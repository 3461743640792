<template>
  <div id="2323" class="dadataScope">
    <vue-dadata
      token="058229d2658e3470e4f8ec3ee1d1d3b91c4bf21d"
      :placeholder="placeholder"
      :onChange="select"
      :locationOptions="locationOptions"
    ></vue-dadata>
  </div>
</template>

<script>
import VueDadata from 'vue-dadata';

export default {
  name: 'VueDadataTest',
  components: {
    'vue-dadata': VueDadata,
  },
  props: {
    placeholder: String,
    locationOptions: Object,
    select: Function,
  },
};
</script>
<style>
.dadataScope .vue-dadata__input {
  color: white;
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  padding: 8px;
  height: 56px;
  font-size: 16px;
}
.dadataScope .vue-dadata__input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}
.dadataScope .vue-dadata__suggestions {
  color: black;
}
</style>
