<template>
  <div>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <div v-if="booking" class="title text-h6">
      {{ titleText }}
    </div>

    <v-date-picker
      v-model="dates"
      first-day-of-week="1"
      full-width
      no-title
      :range="booking"
      :min="minDate"
      :picker-date="defaultShowDate"
      :events="eventDates"
      :allowed-dates="alowedDates"
      @update:picker-date="showNewMounth"
      @click:date="chooseDates"
      event-color="yellow accent-4"
      color="primary"
      class="pb-7"
    >
      <div @click="closeBtn" v-if="showBtn" class="toolTip-back">
        <v-btn class="primary toolTip-btn" @click="clickBtn">
          <v-icon>mdi-check-bold</v-icon>
          <span>Забронировать</span>
        </v-btn>
      </div>
      <div class="toolTip-first">
        <div class="dot-green"></div>
        <div class="ml-4 toolText">- полный день</div>
      </div>
      <div class="toolTip-second">
        <div class="dot-yellow"></div>
        <div class="ml-4 toolText">- неполный день</div>
      </div>
    </v-date-picker>

    <v-row dense v-if="booking">
      <v-col cols="12" sm="6" xs="12" lg="6" md="6" xl="6">
        <v-text-field
          v-model="dateAtText"
          :label="assetHandler.labelAt"
          :error="timeErrorAt"
          readonly
          :hint="
            asset.type == 'apartment'
              ? `Заселение возможно с ${assetHandler.minimumHourAt}:00`
              : undefined
          "
          persistent-hint
        >
          <template v-slot:append>
            <v-icon
              v-if="assetHandler.state.dateAt"
              class="mr-2"
              @click="handleTimeAtIcon"
            >
              mdi-clock-outline
            </v-icon>
            <v-icon
              color="red"
              v-if="assetHandler.state.dateAt"
              @click="deleteDates"
              value="dateAt"
            >
              mdi-close
            </v-icon>
            <v-icon v-if="!assetHandler.state.dateAt">
              mdi-calendar-month
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" xs="12" lg="6" md="6" xl="6">
        <v-text-field
          v-model="dateToText"
          :label="assetHandler.labelTo"
          :error="timeErrorTo"
          readonly
          :hint="
            asset.type == 'apartment'
              ? `Выселение возможно до ${assetHandler.minimumHourTo}:00`
              : undefined
          "
          persistent-hint
        >
          <template v-slot:append>
            <v-icon
              v-if="assetHandler.state.dateTo"
              class="mr-2"
              @click="handleTimeToIcon"
            >
              mdi-clock-outline
            </v-icon>
            <v-icon
              color="red"
              v-if="assetHandler.state.dateTo"
              @click="deleteDates"
              value="dateTo"
            >
              mdi-close
            </v-icon>
            <v-icon v-if="!assetHandler.state.dateTo">
              mdi-calendar-month
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="booking" style="height: 0px">
      <v-col>
        <v-menu
          ref="timeAtMenu"
          v-model="timeAtMenu"
          :close-on-content-click="false"
          :return-value.sync="assetHandler.state.timeAt"
          :position-x="centerX"
          :position-y="100"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          :disabled="
            assetHandler.state.error &&
              assetHandler.state.error.toLowerCase().includes('отказ')
          "
        >
          <div
            v-if="booking && assetHandler.state.booking"
            class="title text-h6"
          >
            время {{ this.assetHandler.textAt }}
          </div>
          <v-time-picker
            format="24hr"
            no-title
            v-if="timeAtMenu && assetHandler.state.booking"
            @click:hour="clickHoursAt"
            :disabled="!assetHandler.state.dateAt"
            v-model="assetHandler.state.timeAt"
            :min="minimumTimeAt"
            :allowed-minutes="allowedMinutesAt"
            :allowed-hours="allowedHoursAt"
            full-width
            @click:minute="saveMinutesAt"
          ></v-time-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          ref="timeToMenu"
          v-model="timeToMenu"
          :close-on-content-click="false"
          :position-x="centerX"
          :position-y="100"
          :return-value.sync="assetHandler.state.timeTo"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          :disabled="
            assetHandler.state.error &&
              assetHandler.state.error.toLowerCase().includes('отказ')
          "
        >
          <div
            v-if="booking && assetHandler.state.booking"
            class="title text-h6"
          >
            время {{ this.assetHandler.textTo }}
          </div>
          <v-time-picker
            format="24hr"
            no-title
            v-if="timeToMenu && assetHandler.state.booking"
            v-model="assetHandler.state.timeTo"
            :allowed-minutes="allowedMinutesTo"
            :allowed-hours="allowedHoursTo"
            full-width
            @click:hour="clickHoursTo"
            @click:minute="saveMinutesTo"
          ></v-time-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
// import _ from "lodash";
export default {
  props: {
    booking: {
      type: Boolean,
      default: false
    },
    asset: {
      type: Object,
      default: () => {
        return {};
      }
    },
    clickOnDate: {
      type: Function,
      default: () => {
        console.log("default click");
      }
    }
  },
  data: () => ({
    // arrayEvents: [...Array(6)].map(() => {
    //   const day = Math.floor(Math.random() * 30);
    //   const d = new Date();
    //   d.setDate(day);
    //   return d.toISOString().substr(0, 10);
    // }),
    dates: [],
    arrayEvents: [],
    showBtn: false,
    timeToMenu: false,
    timeAtMenu: false,
    timepickerAt: null,
    timepickerTo: null,
    close: true,
    defaultShowDate: undefined,
    hourTo: null,
    titleText: "Выберите дату начала",
    datesBucket: {
      dateTo: "",
      dateAt: ""
    },
    loader: false,
    tooltip: {
      availibleFrom: null,
      availibleTo: null
    }
  }),
  computed: {
    ...mapGetters(["bookingState", "bookingApartment", "currentFilial"]),
    // tes() {
    //   const dictionaryMap = {
    //     car: this.bookingState,
    //     apartment: this.bookingApartment,
    //   };
    //   return dictionaryMap[this.asset.type];
    // },
    assetHandler() {
      const dictionaryMap = {
        car: {
          state: this.bookingState,
          updDateAt: this.updateDateAt,
          updDateTo: this.updateDateTo,
          textAt: "начала",
          textTo: "завершения",
          labelAt: "Начало аренды",
          labelTo: "Конец аренды",
          refreshAvalibleTime: this.getDetailsFilialsTime,
          // calculate: this.calculatePrice,
          minimumHourAt: 0,
          minimumHourTo: 25
        }
        // apartment: {
        //   state: this.bookingApartment,
        //   updDateAt: this.updateApartmentDateAt,
        //   updDateTo: this.updateApartmentDateTo,
        //   textAt: 'заселения',
        //   textTo: 'выселения',
        //   labelAt: 'Заселение',
        //   labelTo: 'Выселение',
        //   refreshAvalibleTime: this.getDetailsApartmentFilialsTime,
        //   // calculate: this.calculateApartmentRentPrice,
        //   minimumHourAt: 14,
        //   minimumHourTo: 12,
        // },
      };
      return dictionaryMap[this.asset.type];
    },
    minDate() {
      return moment().format("YYYY-MM-DD");
    },
    centerX() {
      return (window.innerWidth - 290) / 2;
    },
    minimumTimeAt() {
      if (this.assetHandler.state.dateAt) {
        const start = moment(this.assetHandler.state.dateAt);
        const now = moment();
        console.log("same", now.isSame(start, "day"));
        console.log("muinutes::", this.currentFilial.minutesFromNow);
        if (now.isSame(start, "day"))
          return now
            .add(this.currentFilial.minutesFromNow, "minutes")
            .format("HH:mm");
      }
      return undefined;
    },
    dateAtText() {
      // get() {
      let text = "Укажите дату";
      if (this.assetHandler && this.assetHandler.state.dateAt) {
        text = moment(this.assetHandler.state.dateAt).format("DD.MM");
        if (this.assetHandler.state.timeAt) {
          text += ` в ${this.assetHandler.state.timeAt}`;
        }
      }
      return text;
      // },
      // set(d) {
      //   console.log('this sis d', d)
      //   return this.assetHandler.state.commit('updateDateAt', d)
      //   // return this.updateDateAt('2022-06-29')
      // }
    },
    dateToText() {
      let text = "Укажите дату";
      if (this.assetHandler && this.assetHandler.state.dateTo) {
        text = moment(this.assetHandler.state.dateTo).format("DD.MM");
        if (this.assetHandler.state.timeTo) {
          text += ` в ${this.assetHandler.state.timeTo}`;
        }
      }
      return text;
    },
    timeErrorAt() {
      let flag = false;
      if (this.assetHandler.state.timeAt)
        flag =
          this.assetHandler.state.timeAt.split(":")[0] <
          this.assetHandler.minimumHourAt;
      return flag;
    },
    timeErrorTo() {
      let flag = false;
      if (this.assetHandler.state.timeTo)
        flag =
          this.assetHandler.state.timeTo.split(":")[0] >
          this.assetHandler.minimumHourTo;
      return flag;
    }
  },
  methods: {
    ...mapMutations([
      "updateDateAt",
      "updateDateTo",
      "updateApartmentDateAt",
      "updateApartmentDateTo"
    ]),
    ...mapActions([
      "getDetailsFilialsTime",
      // 'calculatePrice',
      "getDetailsApartmentFilialsTime"
      // 'calculateApartmentRentPrice',
    ]),
    handleTimeAtIcon() {
      this.createTooltip(this.dates[0]);
      this.timeAtMenu = true;
    },
    handleTimeToIcon() {
      this.createTooltip(this.dates[1]);
      this.timeToMenu = true;
    },
    showNewMounth() {
      this.defaultShowDate = undefined;
    },
    handleBtn() {
      this.showBtn = !this.showBtn;
    },
    closeBtn() {
      if (this.close) this.assetHandler.updDateAt("");
      this.close = true;
      this.handleBtn();
    },
    clickBtn() {
      this.close = false;
      this.clickOnDate();
    },
    clickHoursAt(v) {
      const string = v.toString();
      this.timepickerAt = string.length == 1 ? "0" + string : string;
    },
    clickHoursTo(v) {
      this.hourTo = v;
      const string = v.toString();
      this.timepickerTo = string.length == 1 ? "0" + string : string;
    },
    saveMinutesAt() {
      this.$refs.timeAtMenu.save(this.assetHandler.state.timeAt);
      if (!this.assetHandler.state.dateTo)
        this.titleText = "Выберите дату завершения";
      if (this.assetHandler.state.dateTo) this.titleText = "Нажмите далее";
    },
    saveMinutesTo() {
      this.$refs.timeToMenu.save(this.assetHandler.state.timeTo);
      this.titleText = "нажмите далее";
    },
    checkMinutes(m, stringToOrAt = "At") {
      let flag = true;

      if (
        this.tooltip.availibleFrom &&
        this.tooltip.availibleTo &&
        stringToOrAt === "At"
      ) {
        const time = `${this.timepickerAt}:${m.length === 1 ? "0" + m : m}`;
        flag = moment(time, "HH:mm").isSameOrAfter(
          moment(this.tooltip.availibleFrom, "HH:mm")
        );
      }
      if (
        this.tooltip.availibleFrom &&
        !this.tooltip.availibleTo &&
        stringToOrAt === "At"
      ) {
        const time = `${this.timepickerAt}:${m.length === 1 ? "0" + m : m}`;
        flag = moment(time, "HH:mm").isSameOrAfter(
          moment(this.tooltip.availibleFrom, "HH:mm")
        );
      }
      if (
        !this.tooltip.availibleFrom &&
        this.tooltip.availibleTo &&
        stringToOrAt === "At"
      ) {
        const time = `${this.timepickerAt}:${m.length === 1 ? "0" + m : m}`;
        flag = moment(time, "HH:mm").isSameOrBefore(
          moment(this.tooltip.availibleTo, "HH:mm")
        );
      }
      if (
        this.tooltip.availibleFrom &&
        this.tooltip.availibleTo &&
        stringToOrAt === "To"
      ) {
        const time = `${this.timepickerTo}:${m.length === 1 ? "0" + m : m}`;
        flag = moment(time, "HH:mm").isSameOrBefore(
          moment(this.tooltip.availibleTo, "HH:mm")
        );
      }
      if (
        this.tooltip.availibleFrom &&
        !this.tooltip.availibleTo &&
        stringToOrAt === "To"
      ) {
        const time = `${this.timepickerTo}:${m.length === 1 ? "0" + m : m}`;
        flag = moment(time, "HH:mm").isSameOrAfter(
          moment(this.tooltip.availibleFrom, "HH:mm")
        );
      }
      if (
        !this.tooltip.availibleFrom &&
        this.tooltip.availibleTo &&
        stringToOrAt === "To"
      ) {
        const time = `${this.timepickerTo}:${m.length === 1 ? "0" + m : m}`;
        flag = moment(time, "HH:mm").isSameOrBefore(
          moment(this.tooltip.availibleTo, "HH:mm")
        );
      }
      return flag;
    },
    allowedMinutesAt(m) {
      const minutesAvalibleBooking = this.checkMinutes(m, "At");
      if (this.asset.type === "apartment")
        return minutesAvalibleBooking && m % 15 === 0;
      if (this.asset.type === "car") {
        if (
          this.assetHandler.state.reservedTimes &&
          this.assetHandler.state.reservedTimes[this.timepickerAt]
        ) {
          return (
            minutesAvalibleBooking &&
            !this.assetHandler.state.reservedTimes[this.timepickerAt].includes(
              m
            ) &&
            m % 15 === 0
          );
        } else return minutesAvalibleBooking && m % 15 === 0;
      }
    },
    allowedMinutesTo(m) {
      const minutesAvalibleBooking = this.checkMinutes(m, "To");
      if (this.asset.type === "apartment") {
        if (this.hourTo == 12) return m == 0;
        return m % 15 === 0;
      }
      if (this.asset.type === "car") {
        if (
          this.assetHandler.state.reservedTimes &&
          this.assetHandler.state.reservedTimes[this.timepickerTo]
        ) {
          return (
            minutesAvalibleBooking &&
            !this.assetHandler.state.reservedTimes[this.timepickerTo].includes(
              m
            ) &&
            m % 15 === 0
          );
        } else return minutesAvalibleBooking && m % 15 === 0;
      }
    },
    allowedHoursAt(x) {
      let hourIsAvailibleByBookings = this.checkHours(x, "At");
      if (hourIsAvailibleByBookings) {
        if (
          this.assetHandler.state.reservedTimes &&
          this.assetHandler.state.reservedTimes[
            x.toString().length === 1 ? "0" + x.toString() : x.toString()
          ] &&
          this.assetHandler.state.reservedTimes[
            x.toString().length === 1 ? "0" + x.toString() : x.toString()
          ].length == 4
        ) {
          return false;
        }
      }
      if (this.asset.type === "apartment") {
        return hourIsAvailibleByBookings && x >= 14 && x.toString();
      }
      if (this.asset.type === "car") {
        if (this.assetHandler.state.deliveryAtNight == "2") {
          return (
            hourIsAvailibleByBookings &&
            moment(x, "HH").isBetween(
              moment(this.assetHandler.state.schedule[0], "HH:mm"),
              moment(this.assetHandler.state.schedule[1], "HH:mm"),
              undefined,
              "[]"
            ) &&
            x.toString()
          );
        } else return hourIsAvailibleByBookings && x.toString();
      }
    },
    allowedHoursTo(x) {
      let hourIsAvailibleByBookings = this.checkHours(x, "To");
      if (this.asset.type === "apartment") {
        return hourIsAvailibleByBookings && x <= 12 && x.toString();
      }
      if (this.asset.type === "car") {
        if (this.assetHandler.state.deliveryAtNight == "2") {
          return (
            hourIsAvailibleByBookings &&
            moment(x, "HH").isBetween(
              moment(this.assetHandler.state.schedule[0], "HH:mm"),
              moment(this.assetHandler.state.schedule[1], "HH:mm"),
              undefined,
              "[]"
            ) &&
            x.toString()
          );
        } else return hourIsAvailibleByBookings && x.toString();
      }
    },
    checkHours(hour) {
      let flag = true;
      let modifiedHour = hour.toString();
      modifiedHour =
        modifiedHour.length === 1 ? "0" + modifiedHour : modifiedHour;
      modifiedHour = modifiedHour + ":00";
      if (this.tooltip.availibleFrom && this.tooltip.availibleTo) {
        flag = moment(modifiedHour, "HH:mm").isBetween(
          moment(this.tooltip.availibleFrom, "HH:mm").startOf("hour"),
          moment(this.tooltip.availibleTo, "HH:mm").startOf("hour"),
          undefined,
          "[]"
        );
      }
      if (this.tooltip.availibleFrom && !this.tooltip.availibleTo) {
        flag = moment(modifiedHour, "HH").isSameOrAfter(
          moment(this.tooltip.availibleFrom, "HH:mm").startOf("hour")
        );
      }
      if (!this.tooltip.availibleFrom && this.tooltip.availibleTo) {
        flag = moment(modifiedHour, "HH").isSameOrBefore(
          moment(this.tooltip.availibleTo, "HH:mm").startOf("hour")
        );
      }
      return flag;
    },
    createTooltip(date) {
      // console.log('this is date: ', date);
      this.tooltip.availibleFrom = null;
      this.tooltip.availibleTo = null;
      if (this.asset.busyDates[date]) {
        this.tooltip.availibleFrom = this.asset.busyDates[date].busyTo;
        this.tooltip.availibleTo = this.asset.busyDates[date].busyFrom;
      }
      // console.log('this is tooltip:', this.tooltip);
    },
    async chooseDates(x) {
      console.log(x);
      if (!this.booking) {
        this.assetHandler.updDateAt(x);
        return this.handleBtn();
      }
      this.createTooltip(x);
      this.assetHandler.state.error = false;
      if (!this.datesBucket.dateAt && this.datesBucket.dateTo) {
        this.dates[0] = x;
        this.dates[1] = this.datesBucket.dateTo;
        this.datesBucket.dateTo = null;
        this.loader = true;
        this.assetHandler.updDateAt(x);
        await this.assetHandler.refreshAvalibleTime();
        this.loader = false;
        this.timeAtMenu = true;
        this.titleText = "Укажите время";
        // this.assetHandler.calculate();
        return;
      }
      if (this.datesBucket.dateAt && !this.datesBucket.dateTo) {
        this.dates[0] = this.datesBucket.dateAt;
        this.dates[1] = x;
        this.datesBucket.dateAt = null;
        this.assetHandler.updDateTo(x);
        this.timeToMenu = true;
        this.titleText = "Укажите время";
        // this.assetHandler.calculate();
        return;
      }
      if (this.dates.length === 1) {
        this.loader = true;
        this.assetHandler.updDateAt(x);
        await this.assetHandler.refreshAvalibleTime();
        this.loader = false;
        this.timeAtMenu = true;
        this.titleText = "Укажите время";
      }

      if (this.dates.length === 2) {
        if (moment(this.dates[1]).isBefore(this.dates[0]))
          return this.assetHandler.updDateTo(this.dates[0]);
        this.assetHandler.updDateTo(x);
        this.titleText = "Укажите время";
        this.timeToMenu = true;
        let start = this.dates[0];
        let end = this.dates[1];

        for (let i = 0; i < this.asset.statuses.length; i++) {
          const { dateAt, dateTo } = this.asset.statuses[i];
          if (
            moment(start).isSameOrBefore(moment(dateAt).startOf("day")) &&
            moment(end).isSameOrAfter(moment(dateTo).startOf("day"))
          ) {
            this.assetHandler.updDateTo(
              moment(dateAt)
                .toISOString()
                .substring(0, 10)
            );
            this.dates[1] = moment(dateAt)
              .toISOString()
              .substring(0, 10);
            break;
          }
        }
        // this.assetHandler.calculate();
      }
      //check if dates in range
      // if ()
    },
    deleteDates(x) {
      // if (x.target.value == 'dateAt') this.updateDateAt('2022-06-29');
      this.assetHandler.state.error = false;
      if (x.target.value == "dateAt") {
        this.titleText = `Укажите дату ${this.assetHandler.textAt}`;
        this.datesBucket.dateTo = this.assetHandler.state.dateTo;
        this.dates[0] = null;
        this.assetHandler.state.dateAt = "";
        this.assetHandler.state.timeAt = "";
      }
      if (x.target.value == "dateTo") {
        this.titleText = `Укажите дату ${this.assetHandler.textTo}`;
        this.datesBucket.dateAt = this.assetHandler.state.dateAt;
        this.dates[1] = null;
        this.assetHandler.state.dateTo = "";
        this.assetHandler.state.timeTo = "";
      }
      if (!this.dates.filter(x => x !== null).length) {
        this.dates = [];
        this.titleText = `Укажите дату ${this.assetHandler.textAt}`;
        this.datesBucket.dateAt = null;
        this.datesBucket.dateTo = null;
      }
    },
    eventDates(d) {
      if (this.asset && this.asset.statuses) {
        for (let i = 0; i < this.asset.statuses.length; i++) {
          //     if (moment(d).isBefore(moment().startOf('day'))) return false
          //     // var aTags = document.getElementsByTagName('div');
          //     // console.log(aTags.length, 'from dates!!!');
          //     // if (aTags && aTags.length) {
          //     //   var searchText = '8';
          //     //   var found;

          //     //   for (var j = 0; j < aTags.length; j++) {
          //     //     if (aTags[j].textContent == searchText) {
          //     //       found = aTags[j];
          //     //       break;
          //     //     }
          //     //   }
          //     //   console.log('found!!!!', found);
          //     // }
          //     if (
          //       moment(d).isSame(
          //         moment(this.asset.statuses[i].dateAt).startOf('day')
          //       ) ||
          //       moment(d).isSame(
          //         moment(this.asset.statuses[i].dateTo).startOf('day')
          //       )
          //     ) {
          //       return true;
          //     }
          if (
            moment(d).isBetween(
              moment(this.asset.statuses[i].dateAt),
              moment(this.asset.statuses[i].dateTo)
            ) &&
            !moment(d).isSame(
              moment(this.asset.statuses[i].dateAt).startOf("day")
            ) &&
            !moment(d).isSame(
              moment(this.asset.statuses[i].dateTo).startOf("day")
            )
          ) {
            return false;
          }
        }
      }
      //   if (this.assetHandler.state.dateAt && this.booking) {
      //     if (moment(d).isBefore(moment(this.assetHandler.state.dateAt)))
      //       return false;
      //   }
      //   if (this.assetHandler.state.dateTo && this.booking) {
      //     if (moment(d).isAfter(moment(this.assetHandler.state.dateTo)))
      //       return false;
      //   }
      //   if (moment(d).isSameOrAfter(moment().startOf('day'))) return ['green'];
      //   return false;
      // }
      if (this.asset?.busyDates?.[d]) return true;

      return moment(d).isSameOrAfter(moment().startOf("day")) && ["green"];
    },
    alowedDates(d) {
      if (this.asset && this.asset.statuses) {
        for (let i = 0; i < this.asset.statuses.length; i++) {
          // var aTags = document.getElementsByTagName('div');
          // console.log(aTags.length, 'from dates!!!');
          // if (aTags && aTags.length) {
          //   var searchText = '8';
          //   var found;

          //   for (var j = 0; j < aTags.length; j++) {
          //     if (aTags[j].textContent == searchText) {
          //       found = aTags[j];
          //       break;
          //     }
          //   }
          //   console.log('found!!!!', found);
          // }
          if (
            moment(d).isBetween(
              moment(this.asset.statuses[i].dateAt),
              moment(this.asset.statuses[i].dateTo)
            ) &&
            !moment(d).isSame(
              moment(this.asset.statuses[i].dateAt).startOf("day")
            ) &&
            !moment(d).isSame(
              moment(this.asset.statuses[i].dateTo).startOf("day")
            )
          ) {
            return false;
          }
        }

        if (this.assetHandler.state.dateAt && this.booking) {
          if (moment(d).isBefore(moment(this.assetHandler.state.dateAt)))
            return false;
        }
        if (this.assetHandler.state.dateTo && this.booking) {
          if (moment(d).isAfter(moment(this.assetHandler.state.dateTo)))
            return false;
        }

        return true;
      }
    }
    // markDisable(day) {
    //   console.log('disable', day);
    // },
  },
  mounted: async function() {
    setTimeout(async () => {
      if (this.asset && this.asset.statuses && this.asset.statuses.length) {
        this.asset.statuses.forEach(status => {
          this.arrayEvents.push(status.dateAt.substring(0, 10));
          this.arrayEvents.push(status.dateTo.substring(0, 10));
        });
      }

      if (this.assetHandler.state.dateAt) {
        this.titleText = `Выберите дату ${this.assetHandler.textTo}`;
        this.dates[0] = this.assetHandler.state.dateAt;
        this.defaultShowDate = this.assetHandler.state.dateAt;
        if (
          !this.assetHandler.state.timeAt ||
          this.assetHandler.state.timeAt.split(":")[0] <
            this.assetHandler.minimumHourAt
        ) {
          this.titleText = `Выберите время ${this.assetHandler.textAt}`;
          this.loader = true;
          await this.assetHandler.refreshAvalibleTime();
          this.loader = false;
          setTimeout(() => {
            this.timeAtMenu = true;
          }, 100);
        }
      }
      if (this.assetHandler.state.dateTo) {
        this.titleText = `Выберите дату ${this.assetHandler.textAt}`;
        this.dates[1] = this.assetHandler.state.dateTo;
        if (this.assetHandler.state.dateAt) this.titleText = "Нажмите далее";
      }
    }, 1000 * 3);
    // setTimeout(() => {
    //   var aTags = document.getElementsByTagName('div');
    //   if (aTags && aTags.length) {
    //     var searchText = '8';
    //     var found;

    //     for (var i = 0; i < aTags.length; i++) {
    //       if (aTags[i].textContent == searchText) {
    //         found = aTags[i];
    //         break;
    //       }
    //     }
    //     console.log('found!!!!', found);
    //     if (found) {
    //       // console.log('this is found', found.parentElement);
    //       let div = document.createElement('div');
    //       div.innerHTML = 'до 10:00';
    //       div.classList.add('eventText');
    //       found.style.color = '#29B6F6';
    //       div.style.color = '#FFD600';
    //       div.style.fontSize = '9px';
    //       div.style.position = 'absolute';
    //       div.style.bottom = '-5px';
    //       found.parentElement.appendChild(div);
    //     }
    //     var searchText2 = '11';
    //     var found2;

    //     for (var j = 0; j < aTags.length; j++) {
    //       if (aTags[j].textContent == searchText2) {
    //         found2 = aTags[j];
    //         break;
    //       }
    //     }
    //     if (found2) {
    //       // console.log('this is found', found.parentElement);
    //       let div2 = document.createElement('div');
    //       div2.innerHTML = 'c 15:30';
    //       div2.classList.add('eventText');
    //       found2.style.color = '#29B6F6';
    //       div2.style.color = '#FFD600';
    //       div2.style.fontSize = '9px';
    //       div2.style.position = 'absolute';
    //       div2.style.bottom = '-5px';
    //       found2.parentElement.appendChild(div2);
    //     }
    //   }
    // }, 0);
  },
  updated: function() {
    // this.$nextTick(function() {});
    if (
      this.assetHandler.state.dateAt &&
      !this.assetHandler.state.timeAt &&
      this.booking
    ) {
      this.dates[0] = this.assetHandler.state.dateAt;
      this.titleText = `Выберите время ${this.assetHandler.textTo}`;
      setTimeout(() => {
        this.timeAtMenu = true;
      }, 1200);
    }
  }
};
</script>

<style lang="scss" scoped>
.toolTip-first {
  position: absolute;
  bottom: 20px;
  right: 10px;
  display: flex;
  text-align: center;
  //   height: 10px;
}
.toolTip-second {
  position: absolute;
  bottom: 20px;
  display: flex;
  text-align: center;
  //   height: 10px;
}
.dot-yellow {
  border-radius: 50%;
  position: absolute;
  //   display: inline-block;
  top: 6px;
  height: 8px;
  margin: 0 1px;
  width: 8px;
  background-color: #ffd600;
  border-color: #ffd600;
}
.dot-green {
  border-radius: 50%;
  position: absolute;
  //   display: inline-block;
  top: 6px;
  height: 8px;
  margin: 0 1px;
  width: 8px;
  background-color: #4caf50;
  border-color: #4caf50;
}
.toolText {
  font-size: 0.9em;
  color: #bdbdbd;
}
.title {
  display: block;
  z-index: 44;
  position: relative;
  margin-bottom: -1px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  background-color: #1976d2;
  border-radius: 10px 10px 0% 0%;
}
.toolTip-back {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
}
.toolTip-btn {
  margin: auto;
}
</style>
