<template>
  <div class="booking-scope">
    <v-overlay :value="spinerForwardVisible || spinerDiscardVisible">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <v-icon @click="goToMenu" class="menuIcon">mdi-menu</v-icon>
    <v-container fluid>
      <v-card height="100" color="grey darken-3">
        <v-layout row wrap v-if="car.status">
          <v-flex lg3 md3 sm4 xs4 xl3>
            <v-card class="ml-3">
              <v-img
                v-if="car.imgLink || car.photos"
                contain
                :src="car.imgLink ? car.imgLink : car.photos[0]"
                height="100"
              ></v-img>
            </v-card>
          </v-flex>

          <v-flex lg9 sm8 xs8 md9 xl9>
            <v-chip small :color="chipColor" class="car_chip">
              {{ this.car.status.name }} {{ this.car.status.date }}</v-chip
            >

            <v-card-title class="primary--text text--lighten-1"
              ><div class="headerText">{{ car.model }}</div></v-card-title
            >
            <v-card-subtitle class="mb-0">
              {{ currentTarif }}
              руб.в сутки

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"
                    >mdi-help-circle-outline</v-icon
                  >
                </template>
                <div v-if="!this.car.tariff">
                  Цена за сутки: {{ this.car.price }} руб.
                </div>
                <div v-if="this.car.tariff && !this.bookingState.tarifToUse">
                  <p>
                    1 сутки -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price * this.car.tariff.firstPeriod) /
                            100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                  <p>
                    2-3 суток -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price * this.car.tariff.secondPeriod) /
                            100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                  <p>
                    4-7 суток -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price * this.car.tariff.thirdPeriod) /
                            100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                  <p>
                    8-14 суток -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price * this.car.tariff.fourthPeriod) /
                            100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                  <p>
                    более 14 суток -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price * this.car.tariff.fivePeriod) / 100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                </div>
                <div v-if="this.car.tariff && this.bookingState.tarifToUse">
                  <p>
                    1 сутки -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price *
                            this.bookingState.tarifToUse.firstPeriod) /
                            100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                  <p>
                    2-3 суток -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price *
                            this.bookingState.tarifToUse.secondPeriod) /
                            100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                  <p>
                    4-7 суток -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price *
                            this.bookingState.tarifToUse.thirdPeriod) /
                            100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                  <p>
                    8-14 суток -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price *
                            this.bookingState.tarifToUse.fourthPeriod) /
                            100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                  <p>
                    более 14 суток -
                    {{
                      Math.round(
                        (this.car.price -
                          (this.car.price *
                            this.bookingState.tarifToUse.fivePeriod) /
                            100) /
                          10
                      ) * 10
                    }}
                    руб.
                  </p>
                </div>
              </v-tooltip> -->
            </v-card-subtitle>
            <v-container fluid>
              <!-- <v-chip small :color="chipColor"> {{car.status.name}} {{car.status.date}}</v-chip> -->
            </v-container>
          </v-flex>
        </v-layout>
        <v-layout
          align-center
          fill-height
          align-content-center
          justify-center
          v-else
        >
          <v-progress-circular indeterminate size="50"></v-progress-circular>
        </v-layout>
      </v-card>

      <v-stepper v-model="this.bookingState.step" class="elevation-0">
        <v-stepper-header class="stepperHeader">
          <v-stepper-step :complete="this.bookingState.step > 1" step="1">
            Даты
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="this.bookingState.step > 2" step="2">
            Расчет
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="this.bookingState.step > 3" step="3">
            Документы
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4"> Оплата </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-container v-if="bookingState.error">
            <h2 class="red darken-2 text-center error-box">
              {{ bookingState.error }}
            </h2>
          </v-container>
          <!-- <v-stepper-content step="1">
            <Phone />            
            <v-layout>
              <v-btn text @click="discard()"> Отмена </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="handleForward()"> Далее </v-btn>
            </v-layout>
          </v-stepper-content> -->
          <v-stepper-content :step="null || 0">
            <v-overlay>
              <v-progress-circular
                indeterminate
                size="50"
              ></v-progress-circular>
            </v-overlay>
          </v-stepper-content>

          <v-stepper-content step="1" class="ma-0 pa-2 pb-5">
            <v-card class="mb-12 px-3 mt-3 stepperOneCard">
              <!-- <v-card flat max-width="200px"> -->
              <v-row v-if="currentFilial.bookingPeriod">
                <p class="text-caption text--secondary pt-2 px-4 text-center">
                  {{ bookingPeriod }}
                </p>
              </v-row>
              <v-row class="datesAndTimes pt-2">
                <v-col class="dates">
                  <!-- <v-row>
                    <v-col>
                      <v-text-field
                        v-model="dateAtText"
                        label="Начало аренды"
                        prepend-icon="mdi-calendar-month"
                        readonly
                      >
                        <template v-slot:append v-if="bookingState.dateAt">
                          <v-icon
                            color="red"
                            @click="deleteDates"
                            value="dateAt"
                          >
                            mdi-close
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="dateToText"
                        label="Конец аренды"
                        prepend-icon="mdi-calendar-month"
                        readonly
                      >
                        <template v-slot:append v-if="bookingState.dateTo">
                          <v-icon
                            color="red"
                            @click="deleteDates"
                            value="dateTo"
                          >
                            mdi-close
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row> -->
                  <DateIntervalsAsset :booking="true" :asset="car" />
                </v-col>

                <!-- <v-col cols="12" sm="5" md="4" lg="3" xl="2" class="times">
                  <v-menu
                    ref="timeAtMenu"
                    v-model="timeAtMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="bookingState.timeAt"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :disabled="
                      bookingState.error &&
                        bookingState.error.toLowerCase().includes('отказ')
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="bookingState.timeAt"
                        label="Время начала аренды"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        class="date-at-fields"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      format="24hr"
                      no-title
                      v-if="timeAtMenu"
                      @click:hour="clickHours"
                      :disabled="!bookingState.dateAt"
                      v-model="bookingState.timeAt"
                      :min="minimumTimeAt"
                      :allowed-minutes="allowedStep"
                      :allowed-hours="allowedHours"
                      full-width
                      @click:minute="$refs.timeAtMenu.save(bookingState.timeAt)"
                    ></v-time-picker>
                  </v-menu>

                  <v-menu
                    ref="timeToMenu"
                    v-model="timeToMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="bookingState.timeTo"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    :disabled="
                      bookingState.error &&
                        bookingState.error.toLowerCase().includes('отказ')
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="bookingState.timeTo"
                        label="Время завершения аренды"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        class="date-at-fields"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      format="24hr"
                      no-title
                      v-if="timeToMenu"
                      v-model="bookingState.timeTo"
                      :allowed-minutes="allowedStep"
                      :allowed-hours="allowedHours"
                      full-width
                      @click:minute="$refs.timeToMenu.save(bookingState.timeTo)"
                    ></v-time-picker>
                  </v-menu>
                </v-col> -->
              </v-row>
              <!-- </v-card> -->
              <!-- <template>
                <v-row v-if="currentFilial.bookingPeriod">
                  <p class="text-caption text--secondary pt-2 px-4 text-center">
                    {{ bookingPeriod }}
                  </p>
                </v-row>
                <v-row class="pt-3">
                  <v-col cols="11" sm="5" class="py-0">
                    <v-menu
                      ref="dateAtMenu"
                      v-model="dateAtMenu"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      :return-value.sync="bookingState.dateAt"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      :disabled="
                        bookingState.error &&
                          bookingState.error.toLowerCase().includes('отказ')
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateAtText"
                          label="Дата начала аренды"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          class="date-at-fields"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="minDateTo('dateAt')"
                        :max="maxDateTo()"
                        first-day-of-week="1"
                        no-title
                        v-if="dateAtMenu"
                        v-model="bookingState.dateAt"
                        full-width
                        @click:date="chooseDateAt"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="11" sm="5" class="py-0">
                    <v-menu
                      ref="timeAtMenu"
                      v-model="timeAtMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="bookingState.timeAt"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      :disabled="
                        bookingState.error &&
                          bookingState.error.toLowerCase().includes('отказ')
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="bookingState.timeAt"
                          label="Время начала аренды"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          class="date-at-fields"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        no-title
                        v-if="timeAtMenu"
                        @click:hour="clickHours"
                        :disabled="!bookingState.dateAt"
                        v-model="bookingState.timeAt"
                        :min="minimumTimeAt"
                        :allowed-minutes="allowedStep"
                        :allowed-hours="allowedHours"
                        full-width
                        @click:minute="
                          $refs.timeAtMenu.save(bookingState.timeAt)
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </template>

              <template>
                <v-row>
                  <v-col cols="11" sm="5" class="py-0">
                    <v-menu
                      ref="dateToMenu"
                      v-model="dateToMenu"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      :return-value.sync="bookingState.dateTo"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      :disabled="
                        bookingState.error &&
                          bookingState.error.toLowerCase().includes('отказ')
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateToText"
                          label="Дата завершения аренды"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          class="date-at-fields"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="minDateTo('dateTo')"
                        :max="maxDateTo()"
                        first-day-of-week="1"
                        no-title
                        v-if="dateToMenu"
                        v-model="bookingState.dateTo"
                        full-width
                        @click:date="$refs.dateToMenu.save(bookingState.dateTo)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="11" sm="5" class="py-0">
                    <v-menu
                      ref="timeToMenu"
                      v-model="timeToMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="bookingState.timeTo"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      :disabled="
                        bookingState.error &&
                          bookingState.error.toLowerCase().includes('отказ')
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="bookingState.timeTo"
                          label="Время завершения аренды"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          class="date-at-fields"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        no-title
                        v-if="timeToMenu"
                        v-model="bookingState.timeTo"
                        :allowed-minutes="allowedStep"
                        :allowed-hours="allowedHours"
                        full-width
                        @click:minute="
                          $refs.timeToMenu.save(bookingState.timeTo)
                        "
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </template> -->
              <template>
                <v-row>
                  <div
                    v-if="
                      (pickTimeOutOfSchedule || dropTimeOutOfSchedule) &&
                        currentFilial.deliveryAtNight == '1' &&
                        currentFilial.deliveryNonWorkHours &&
                        currentFilial.deliveryNonWorkHours.length > 0
                    "
                    class="ml-4"
                  >
                    <p class="text-caption text--secondary">
                      В указанное Вами время за
                      {{
                        pickTimeOutOfSchedule
                          ? dropTimeOutOfSchedule
                            ? "выдачу/сдачу"
                            : "сдачу"
                          : "выдачу"
                      }}
                      будет взиматься дополнительная плата в размере
                      {{
                        pickTimeOutOfSchedule
                          ? dropTimeOutOfSchedule
                            ? `${currentFilial.deliveryNonWorkHours.reduce(
                                (prev, curr) =>
                                  prev.values["4"] + curr.values["4"]
                              )}`
                            : `${currentFilial.deliveryNonWorkHours[0].values[4]}`
                          : `${
                              currentFilial.deliveryNonWorkHours[1]
                                ? currentFilial.deliveryNonWorkHours[1]
                                    .values[4]
                                : null
                            }`
                      }}
                      руб. Вы можете избежать этого, указав время с
                      {{ currentFilial.schedule[0] }} до
                      {{ currentFilial.schedule[1] }} часов.
                    </p>
                  </div>
                </v-row>
              </template>
              <div class="dochelper">
                <v-alert
                  transition="scale-transition"
                  class="ml-3 mt-1 mb-6"
                  dense
                  outlined
                  color="red lighten-1"
                  :value="!!messageError"
                  ><p class="ma-0">· {{ messageError }}</p></v-alert
                >
              </div>
              <template v-if="car.tariff">
                <v-row>
                  <!-- <p v-if="bookingState.currentTarifTitle">{{bookingState.currentTarifTitle}}</p> -->
                  <v-expansion-panels>
                    <v-expansion-panel v-if="this.calculateTarif">
                      <v-expansion-panel-header
                        color="blue-grey darken-4"
                        id="moreAboutPriceBooking"
                      >
                        Подробнее о цене {{ bookingState.currentTarifTitle }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <thead>
                            <tr>
                              <th>
                                Период аренды
                              </th>
                              <th>
                                Стоимость
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(value, key, index) in calculateTarif"
                              :key="index"
                            >
                              <td>
                                {{ key }}
                              </td>
                              <td>{{ value }} руб.</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </template>
            </v-card>

            <v-layout>
              <v-btn
                text
                @click="goBack"
                :loading="spinerDiscardVisible"
                :disabled="
                  bookingState.unfinishedBookings &&
                    bookingState.unfinishedBookings.length === 0 &&
                    bookingState.error == ''
                "
              >
                <v-icon>mdi-keyboard-backspace</v-icon> <span>Все авто</span>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="handleForward()"
                :disabled="
                  !bookingState.dateAt ||
                    !bookingState.dateTo ||
                    bookingState.step == null ||
                    !buttonVisible ||
                    !!bookingState.error
                "
                :loading="bookingState.step == null || spinerForwardVisible"
              >
                Далее
              </v-btn>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="2" class="ma-0 pa-2 pb-5">
            <v-card class="mb-12">
              <v-card-text class="px-1">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-select
                        :disabled="disabledFields"
                        :items="deliveryPlacesPick"
                        item-text="values[4]"
                        v-model="bookingState.placeToPick"
                        label="Место выдачи"
                        required
                        outlined
                        return-object
                        @change="updatePlaceToPickMethod"
                      ></v-select>
                      <p
                        v-if="
                          currentFilial.deliveryAvalible == 2 &&
                            pickTimeOutOfSchedule
                        "
                        class="ma-0 mt-n5"
                      >
                        В нерабочее время выдача возможна только в офисе
                      </p>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="adresAtDisabled" class="py-0">
                      <v-text-field
                        v-model="adresAt"
                        :label="
                          adresAtDisabled ? 'Адрес выдачи' : 'Укажите адрес'
                        "
                        :disabled="adresAtDisabled"
                        >{{ adresAt }}</v-text-field
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      v-if="!adresAtDisabled"
                      class="py-0"
                    >
                      <VueDadataTest
                        :select="selectCustomPlaceToPick"
                        :placeholder="'Укажите адрес'"
                        :locationOptions="bookingLocationOptionsPick"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                      <v-select
                        :items="deliveryPlacesDrop"
                        :disabled="disabledFields"
                        label="Место сдачи"
                        required
                        outlined
                        return-object
                        item-text="values[4]"
                        v-model="bookingState.placeToDrop"
                        @change="updatePlaceToDropMethod"
                      ></v-select>
                      <!-- Отключено т.к. сдача в нерабочее время на данный момент доступна всегда, даже если филиал не робит -->
                      <!-- <p
                        v-if="
                          currentFilial.deliveryAvalible == 2 &&
                            dropTimeOutOfSchedule
                        "
                        class="ma-0 mt-n5"
                      >
                        В нерабочее время сдача возможна только в офисе
                      </p> -->
                    </v-col>
                    <v-col cols="12" sm="6" v-if="adresToDisabled" class="py-0">
                      <v-text-field
                        v-model="adresTo"
                        :label="
                          adresToDisabled ? 'Адрес сдачи' : 'Укажите адрес'
                        "
                        :disabled="adresToDisabled"
                        >{{ adresTo }}</v-text-field
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      v-if="!adresToDisabled"
                      class="py-0"
                    >
                      <VueDadataTest
                        :select="selectCustomPlaceToDrop"
                        :placeholder="'Укажите адрес'"
                        :locationOptions="bookingLocationOptionsDrop"
                      />
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0">
                      <!-- <h3>Дополнительные услуги</h3> -->
                      <v-list class="ma-0 py-0 px-2">
                        <v-container class="ma-0 pa-0">
                          <v-list-item
                            v-for="(service, i) in currentFilial.shownServices"
                            :key="i"
                            class="ma-0 pa-0"
                          >
                            <v-list-item-action>
                              <v-checkbox
                                :disabled="disabledFields"
                                :input-value="checkedBox(service)"
                                @click="clickCheckboxService(service)"
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-title>{{
                              service.title
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-container>
                      </v-list>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        label="Пожелания"
                        v-model="bookingState.comment"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        label="Промокод"
                        v-model="promocode"
                        @click="promoMessage = ''"
                        :disabled="promoLock"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" align-self="center" class="py-0">
                      <v-btn
                        small
                        @click="checkPromoCode"
                        :disabled="promoLock || !promocode.length"
                        :loading="promoSpinner"
                        >Применить</v-btn
                      >
                    </v-col>
                    <v-alert
                      transition="scale-transition"
                      class="ml-3 mt-n3 mb-6"
                      dense
                      outlined
                      :type="promoType"
                      :value="!!promoMessage"
                      >{{ promoMessage }}</v-alert
                    >
                  </v-row>

                  <v-container
                    v-model="bookingState.priceText"
                    style="padding: 0px; margin: 0px"
                  >
                    <v-progress-circular
                      indeterminate
                      size="50"
                      class="loader"
                      v-if="priceLoading"
                    ></v-progress-circular>
                    <div
                      class="price-text"
                      :class="{ 'price-text--inactive': priceLoading }"
                    >
                      <p
                        v-for="(text, i) in bookingState.priceText
                          .split('Итого:')[0]
                          .split('\n')"
                        :key="i"
                        style="margin: 0px"
                      >
                        {{ text }}
                      </p>
                      <h4>
                        Итого{{
                          bookingState.discounts.title
                            ? " с учетом скидки:"
                            : ":"
                        }}
                      </h4>
                      <span>
                        {{ bookingState.priceText.split("Итого:")[1] }}
                      </span>
                    </div>
                  </v-container>
                </v-container>
              </v-card-text>
            </v-card>

            <div class="dochelper">
              <v-alert
                transition="scale-transition"
                class="ml-3 mt-n5 mb-6"
                dense
                outlined
                color="red lighten-1"
                :value="
                  (!adresAtDisabled && !adresAt) ||
                    (!adresToDisabled && !adresTo)
                "
                ><p class="ma-0">Укажите адрес, включая номер дома</p></v-alert
              >
            </div>
            <v-layout>
              <v-btn text @click="handleBack()"> Назад </v-btn>

              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                :loading="spinerForwardVisible || promoSpinner"
                @click="handleForward()"
                :disabled="
                  !bookingState.placeToPick ||
                    !bookingState.placeToDrop ||
                    (!adresAtDisabled && !adresAt) ||
                    (!adresToDisabled && !adresTo) ||
                    !!bookingState.error
                "
              >
                Далее
              </v-btn>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="3" class="ma-0 pa-2 pb-5">
            <ProfileCard></ProfileCard>
            <!-- поднял наверх, чтобы пробрасываемую ошибку было видно из всех шагов -->
            <!-- <h2 v-if="bookingState.error" class="red">
              {{ bookingState.error }}
            </h2> -->
            <div class="dochelper">
              <v-alert
                transition="scale-transition"
                class="ml-3 mt-n5 mb-6"
                dense
                outlined
                color="red lighten-1"
                :value="!!docStepDisabled.length"
                ><p
                  v-for="(text, i) in docStepDisabled"
                  :key="i"
                  class="subtitle-2"
                  style="margin: 0px"
                >
                  · {{ text }}
                </p></v-alert
              >
            </div>
            <v-layout>
              <v-btn text @click="handleBack()"> Назад </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="handleForward()"
                :loading="spinerForwardVisible"
                :disabled="!!docStepDisabled.length || !!bookingState.error"
              >
                Далее
              </v-btn>
            </v-layout>
          </v-stepper-content>
          <v-dialog
            v-model="discardDialog"
            v-if="showDialogDiscard"
            max-width="480px"
          >
            <v-card max-width="480px">
              <v-card-title> Вы уверены? </v-card-title>
              <v-card-text>
                Машина
                {{ bookingState.status == 3 ? "" : "предварительно" }}
                забронирована, если хотите изменить бронирование, обратитесь в
                <span
                  class="
                    primary--text
                    text--lighten-1 text-decoration-underline
                  "
                  @click="sendMessageToContactCenter"
                  >Контакт центр</span
                >
              </v-card-text>
              <v-card-actions>
                <v-btn @click="showDialogDiscard()">назад</v-btn>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on">
                      <v-icon color="red">mdi-close</v-icon
                      ><span>Отменить бронь</span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in denyReasonsAuto"
                      @click="discard(item.id)"
                      :key="index"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-stepper-content step="4" class="ma-0 pa-2 pb-5">
            <v-card class="mb-12">
              <div
                class="progresContainer mx-15"
                v-if="bookingState.infoText.progress.show"
              >
                <v-card-text class="text-center ma-0">
                  {{ bookingState.infoText.progress.text }}
                </v-card-text>
                <v-progress-linear
                  :buffer-value="bookingState.infoText.progress.procent"
                  stream
                  color="cyan"
                  class="mb-2 px-0 mx-0"
                ></v-progress-linear>
              </div>
              <v-card-text>
                <div v-if="bookingState.infoText">
                  <!-- <h1 class="primary--text text--lighten-1 header-bron">
                    Автомобиль {{ car.model }} {{ car.gosNumber }}
                    {{
                      bookingState.status == 3 ? "" : "предварительно"
                    }}
                    забронирован
                  </h1> -->
                  <h1 class="primary--text text--lighten-1 header-bron px-4">
                    {{ bookingState.infoText.title }}
                  </h1>
                  <template
                    v-if="
                      bookingState.infoText.payButton ||
                        bookingState.infoText.refreshButton ||
                        bookingState.infoText.closeDialog
                    "
                  >
                    <v-card-text v-if="bookingState.infoText.textDelivery">
                      {{ bookingState.infoText.textDelivery }}
                    </v-card-text>
                    <v-card-text v-if="bookingState.infoText.textPay">
                      {{ bookingState.infoText.textPay }}
                    </v-card-text>
                    <v-row justify="space-between" class="mx-3 my-3">
                      <v-btn
                        v-if="bookingState.infoText.refreshButton"
                        @click="refreshInfo"
                        color="secondary"
                        class="px-3"
                      >
                        Обновить
                      </v-btn>
                      <v-btn
                        v-if="bookingState.infoText.payButton"
                        @click="goToPaymentPage"
                        color="primary"
                        class="px-3"
                      >
                        Оплатить
                      </v-btn>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-card-text>
                      <h2 class="primary--text text--lighten-1">Выдача</h2>
                      <h4>{{ dateAtFormated }}</h4>
                      <h4
                        v-if="
                          bookingState.adresToPick &&
                            bookingState.adresToPick.value
                        "
                      >
                        {{
                          bookingState.adresToPick.value
                            ? bookingState.adresToPick.value
                            : bookingState.placeToPick.title
                        }}
                      </h4>
                    </v-card-text>
                    <v-card-text>
                      <h2 class="primary--text text--lighten-1">Сдача</h2>
                      <h4>{{ dateToFormated }}</h4>
                      <h4
                        v-if="
                          bookingState.adresToDrop &&
                            bookingState.adresToDrop.value
                        "
                      >
                        {{
                          bookingState.adresToDrop.value
                            ? bookingState.adresToDrop.value
                            : bookingState.placeToDrop.title
                        }}
                      </h4>
                    </v-card-text>
                    <v-card-text>
                      <h4
                        v-for="(priceText, i) in bookingState.priceText
                          .split('Итого:')[0]
                          .split('\n')"
                        :key="i"
                      >
                        {{ priceText }}
                      </h4>
                    </v-card-text>
                    <v-card-text>
                      <h2 class="primary--text text--lighten-1">Итого:</h2>
                      <h3>{{ bookingState.priceText.split("Итого:")[1] }}</h3>
                    </v-card-text>
                  </template>
                </div>
                <div v-else>
                  <!-- <h1 class="primary--text text--lighten-1 header-bron">
                    Автомобиль {{ car.model }} {{ car.gosNumber }}
                    {{
                      bookingState.status == 3 ? "" : "предварительно"
                    }}
                    забронирован
                  </h1> -->
                  <h1 class="primary--text text--lighten-1 header-bron">
                    Получили Ваши документы
                  </h1>

                  <v-card-text>
                    👌 Документы переданы для проверки службой безопасности. В
                    ближайшее время с Вами свяжутся, либо Вы можете нажать
                    кнопку обновить и получить актуальную информацию онлайн.
                  </v-card-text>
                </div>
              </v-card-text>

              <!-- <v-row
                justify="center"
                class="py-4"
                v-if="
                  bookingState.infoText &&
                    (bookingState.infoText.inspectionUploadButton ||
                      bookingState.infoText.inspectionResultButton)
                "
              >
                <v-btn @click="goToInspections">осмотр авто</v-btn>
              </v-row> -->
              <v-row justify="space-between" class="pa-2 mx-3">
                <template
                  v-if="
                    bookingState.infoText &&
                      (bookingState.infoText.inspectionUploadButton ||
                        bookingState.infoText.inspectionResultButton)
                  "
                  ><v-btn
                    color="primary"
                    @click="goToInspections"
                    class="mx-3 my-2"
                    >осмотр авто</v-btn
                  >
                  <v-spacer
                /></template>
                <v-btn
                  cl
                  color="primary"
                  v-if="
                    !bookingState.infoText.closeDialog &&
                      !bookingState.infoText.refreshButton
                  "
                  @click="payHandler"
                  class="mx-3 my-2"
                  >Оплатить</v-btn
                >
              </v-row>
              <v-dialog
                v-if="bookingState.showInspection"
                v-model="bookingState.showInspection"
                class="overflow-hidden"
                persistent
                fullscreen
                hide-overlay
              >
                <Inspections
                  :uploadPhoto="bookingState.infoText.inspectionUploadButton"
                />
              </v-dialog>
            </v-card>
            <v-layout>
              <v-btn
                v-if="!bookingState.infoText.closeDialog"
                small
                text
                class="px-0"
                @click="showDialogDiscard()"
                :loading="spinerDiscardVisible"
                :disabled="
                  (bookingState.unfinishedBookings &&
                    bookingState.unfinishedBookings.length === 0 &&
                    bookingState.error == '') ||
                    [3, 4, 7].includes(+bookingState.status)
                "
              >
                <v-icon color="red">mdi-close</v-icon
                ><span>Отменить заявку</span></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn small color="secondary" @click="sendMessageToContactCenter"
                >Контакт-центр
              </v-btn>
            </v-layout>
          </v-stepper-content>
          <!-- Шаг переделан в рамках задачи RC_beta Реализуем -->
          <!-- <v-stepper-content step="4">
            <v-card class="mb-12">
              <v-card-text>
                <div
                  v-if="bookingState.error"
                  :class="
                    bookingState.error.includes('Документы') ? null : 'red'
                  "
                >
                  <h2
                    v-for="(sentense, i) in bookingState.error.split('. ')"
                    :key="i"
                  >
                    {{ sentense }}
                  </h2>
                </div>

                <div v-if="!bookingState.error">
                  <h1 class="primary--text text--lighten-1">
                    Автомобиль {{ car.model }}
                    {{ car.gosNumber }} {{bookingState.status == 3 ? '' : 'предварительно'}} забронирован
                  </h1>

                  <v-card-text>
                    <h2 class="primary--text text--lighten-1">Выдача</h2>
                    <h4>{{ dateAtFormated }}</h4>
                    <h4 v-if="bookingState.placeToPick">
                      {{
                        bookingState.placeToPick.recordTitle
                          ? bookingState.placeToPick.recordTitle
                          : bookingState.placeToPick.title
                      }}
                    </h4>
                  </v-card-text>
                  <v-card-text>
                    <h2 class="primary--text text--lighten-1">Сдача</h2>
                    <h4>{{ dateToFormated }}</h4>
                    <h4 v-if="bookingState.placeToDrop">
                      {{
                        bookingState.placeToDrop.recordTitle
                          ? bookingState.placeToDrop.recordTitle
                          : bookingState.placeToDrop.title
                      }}
                    </h4>
                  </v-card-text>
                  <v-card-text>
                    <h4
                      v-for="(priceText, i) in bookingState.priceText
                        .split('Итого:')[0]
                        .split('\n')"
                      :key="i"
                    >
                      {{ priceText }}
                    </h4>
                  </v-card-text>
                  <v-card-text>
                    <h2 class="primary--text text--lighten-1">Итого:</h2>
                    <h3>{{ bookingState.priceText.split("Итого:")[1] }}</h3>
                  </v-card-text>
                  <h3>
                    ❗️ для подтверждение брони внесите оплату в течение часа
                  </h3>
                  <h3 v-if="bookingState.priceText.includes('нерабочее')">
                    ⚠️в ближайшее время с вами свяжется менеджер для
                    согласнования доставки
                  </h3>
                </div>
              </v-card-text>
            </v-card>
            <v-layout>
              <v-btn
                small
                text
                @click="showDialogDiscard()"
                :loading="spinerDiscardVisible"
                :disabled="
                  bookingState.unfinishedBookings.length === 0 &&
                  bookingState.error == ''
                "
              >
                <v-icon color="red">mdi-close</v-icon
                ><span>Отменить заявку</span></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                v-if="!bookingState.error"
                small
                color="primary"
                @click="handleForward()"
                :disabled="!!bookingState.error"
                >Оплатить
              </v-btn>
              <v-btn
                v-if="!!bookingState.error"
                small
                color="primary"
                @click="sendMessageToContactCenter"
                >Контакт-центр
              </v-btn>
            </v-layout>
          </v-stepper-content> -->
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
import ProfileCard from "./ProfileCard";
// import DocLoad from "./DocLoad.vue";
import Inspections from "./Inspections";
import VueDadataTest from "./utils/VueDadataTest.vue";
import DateIntervalsAsset from "./utils/DateIntervalsAsset.vue";
// import TimePicker from './utils/TimePicker.vue'
// import { VDigitalTimePicker } from 'v-digital-time-picker'
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      // stepper: {
      //   e1: 1,
      // },
      disabledFields: false,
      spinerForwardVisible: false,
      spinerDiscardVisible: false,
      priceLoading: false,
      buttonVisible: false,
      dateAtMenu: false,
      timeAtMenu: false,
      dateToMenu: false,
      timeToMenu: false,
      customPlaceToPick: "",
      customPlaceToDrop: "",
      discardDialog: false,
      timepicker: null,
      bucket: null,
      promocode: "",
      promoSpinner: false,
      promoMessage: "",
      promoType: null,
      promoLock: false,
      messageError: "",
      adresAt: "",
      adresAtDisabled: true,
      adresTo: "",
      adresToDisabled: true,
      customCityNameDrop: "",
      customCityNamePick: "",
      timeTest: "08:30"
    };
  },
  mounted: async function() {
    // проверить через степпер и только тогда вызывать
    if (this.car) {
      await this.getCarById(this.car.id);
      await this.findFilialById(this.car.filialId);
      this.bucket =
        "Некоторый костыль для расчета цены, чтобы данные в сторе обновились";
    }
    if (this.bookingState.priceText === "") {
      // this.calculatePrice();
    }

    await this.getPriceData();
    if (this.bookingState.infoText) {
      if (
        this.bookingState.infoText.inspectionUploadButton
        // ||
        // this.bookingState.infoText.inspectionResultButton
      ) {
        this.goToInspections();
      }
      if (this.bookingState.infoText.reduceStatus) {
        setTimeout(async () => {
          await this.backStepper(1);
        }, 5000);
      }
      if (this.bookingState.infoText.closeDialog) {
        setTimeout(async () => {
          await this.resetBookingState();
        }, 5000);
      }
    }

    if (
      this.bookingState &&
      this.bookingState.placeToPick &&
      this.bookingState.placeToPick.values[14].value
    ) {
      this.adresAt =
        (this.bookingState.placeToPick.values[14].data.street_with_type ||
          this.bookingState.placeToPick.values[14].settlement_with_type) +
        ", " +
        this.bookingState.placeToPick.values[14].data.house_type +
        " " +
        this.bookingState.placeToPick.values[14].data.house;
      this.updateAdresToPick(this.bookingState.placeToPick.values[14]);
    }
    if (
      this.bookingState &&
      this.bookingState.placeToDrop &&
      this.bookingState.placeToDrop.values[14].value
    ) {
      this.adresTo =
        (this.bookingState.placeToDrop.values[14].data.street_with_type ||
          this.bookingState.placeToDrop.values[14].settlement_with_type) +
        ", " +
        this.bookingState.placeToDrop.values[14].data.house_type +
        " " +
        this.bookingState.placeToDrop.values[14].data.house;
      this.updateAdresToDrop(this.bookingState.placeToDrop.values[14]);
    }
    // if (this.socket) {
    //   console.log('hey i got a socket!');
    //   this.socket.on('booking:update', (data) => {
    //     this.refreshStoreBySocket(data);
    //   });
    // }
    if (this.bookingState && this.bookingState.promocode.length) {
      this.promoLock = true;
      this.promocode = this.bookingState.promocode[0].recordTitle;
    }
  },
  methods: {
    goToMenu() {
      this.$router.push({ path: "/operations/invest" });
    },
    // Адрес кастомный должен отличаться от остальных какой-нибудь вэльюс а не по айдишникам
    async updatePlaceToPickMethod() {
      this.customCityNamePick = "";
      this.updatePlaceToPick;
      this.adresAtDisabled = true;
      this.spinerForwardVisible = true;
      this.priceLoading = true;
      this.disabledFields = true;
      setTimeout(() => {
        this.priceLoading = false;
        this.disabledFields = false;
      }, 1500);
      await this.socket.emit(
        "request",
        {
          resource: "booking",
          method: "patch",
          data: {
            id: this.bookingState.currentBookingId,
            placeToPickLink: [
              {
                catalogId: this.bookingState.placeToPick.catalogId,
                recordId: this.bookingState.placeToPick.id
              }
            ]
          }
        },
        res => console.log(res)
      );
      // this.priceLoading = false;
      this.spinerForwardVisible = false;
      if (this.bookingState.placeToPick.values[16][0] == 2) {
        this.adresAtDisabled = false;
        if (this.bookingState.placeToPick.values[4].split(" ").length == 1)
          this.customCityNamePick = this.bookingState.placeToPick.values[4];
        this.adresAt = "";
        return;
      }
      if (this.bookingState.placeToPick.values[14].value) {
        this.adresAt =
          this.bookingState.placeToPick.values[14].data.street_with_type +
          ", " +
          this.bookingState.placeToPick.values[14].data.house_type +
          " " +
          this.bookingState.placeToPick.values[14].data.house;
        this.updateAdresToPick(this.bookingState.placeToPick.values[14]);
      }
    },
    async updatePlaceToDropMethod() {
      this.customCityNameDrop = "";
      this.updatePlaceToDrop;
      this.adresToDisabled = true;
      this.spinerForwardVisible = true;
      this.priceLoading = true;
      this.disabledFields = true;
      setTimeout(() => {
        this.priceLoading = false;
        this.disabledFields = false;
      }, 1500);
      await this.socket.emit(
        "request",
        {
          resource: "booking",
          method: "patch",
          data: {
            id: this.bookingState.currentBookingId,
            placeToDropLink: [
              {
                catalogId: this.bookingState.placeToDrop.catalogId,
                recordId: this.bookingState.placeToDrop.id
              }
            ]
          }
        },
        res => console.log(res)
      );
      // this.priceLoading = false
      this.spinerForwardVisible = false;
      if (this.bookingState.placeToDrop.values[16][0] == 2) {
        this.adresToDisabled = false;
        if (this.bookingState.placeToDrop.values[4].split(" ").length == 1)
          this.customCityNameDrop = this.bookingState.placeToDrop.values[4];
        this.adresTo = "";
        return;
      }
      if (this.bookingState.placeToDrop.values[14].value) {
        this.adresTo =
          this.bookingState.placeToDrop.values[14].data.street_with_type +
          ", " +
          this.bookingState.placeToDrop.values[14].data.house_type +
          " " +
          this.bookingState.placeToDrop.values[14].data.house;
        this.updateAdresToDrop(this.bookingState.placeToDrop.values[14]);
      }
    },
    goToInspections() {
      this.handleInspections;
    },
    async refreshInfo() {
      this.spinerForwardVisible = true;
      await this.refreshInfoDocuments();
      this.spinerForwardVisible = false;
      if (this.bookingState.infoText) {
        if (this.bookingState.infoText.reduceStatus) {
          setTimeout(async () => {
            await this.backStepper(1);
          }, 5000);
        }

        if (this.bookingState.infoText.closeDialog) {
          setTimeout(async () => {
            await this.resetBookingState();
          }, 5000);
        }
      }
    },
    goToPaymentPage() {
      return window.open(
        `${window.location.origin}/pay/${this.bookingState.payId}`,
        "_blank"
      );
    },
    goToPaymentPageStatic() {
      return window.open(`${this.currentFilial.paymentLink}`, "_blank");
    },
    payHandler() {
      if (this.bookingState.infoText.payButton) return this.goToPaymentPage();
      return this.goToPaymentPageStatic();
    },
    async goBack() {
      this.spinerForwardVisible = true;
      await this.backStepper(0);
      this.updatePlaceToPick(null);
      this.updatePlaceToDrop(null);
      this.updateAdresToPick(null);
      this.updateAdresToDrop(null);
      this.adresAt = "";
      this.adresTo = "";
      this.spinerForwardVisible = false;
      this.updateBookingState(this.car);
    },
    async checkPromoCode() {
      this.promoSpinner = true;
      this.promoMessage = "";
      const data = await this.checkCode(this.promocode);
      this.promoType = data.typeStyle;
      this.promoMessage = data.message;
      if (data.typeStyle === "success") this.promoLock = true;
      this.promoSpinner = false;
    },
    async showDialogDiscard() {
      await this.getDenyReasons();
      this.getDetailsFilialsTime();
      this.discardDialog = !this.discardDialog;
    },
    setStatus(status) {
      this.status = status;
    },
    sendMessageToContactCenter() {
      const text = `Добрый день, хочу внести изменения в бронирование по машине ${this.car.model} госномер ${this.car.gosNumber}`;
      const url = `whatsapp://send?phone=79009210281&text=${text.replace(
        / /g,
        "%20"
      )}`;
      window.open(url, "_blank").focus();
    },
    selectCustomPlaceToDrop(x) {
      this.updateAdresToDrop(x);
      if (x.data.house) this.adresTo = x.value;
    },
    selectCustomPlaceToPick(x) {
      this.updateAdresToPick(x);
      if (x.data.house) this.adresAt = x.value;
    },
    async clickCheckboxService(service) {
      // console.log('click on service: ', service);
      // console.log(
      //   'this.bookingState.chosenServices: before::',
      //   this.bookingState.chosenServices
      // );
      this.disabledFields = true;
      this.priceLoading = true;
      if (this.bookingService.find(x => x.id == service.id)) {
        // console.log('finded!');
        this.updateChosenServices(
          this.bookingService.filter(x => x.id !== service.id)
        );
      } else {
        this.updateChosenServices([...this.bookingService, service]);
      }
      this.spinerForwardVisible = true;
      // await this.directlyPatch('placeToPick')
      const serv = [
        ...new Map(
          [
            ...this.bookingState.chosenServices,
            ...this.bookingState.mandatoryServices
          ].map(item => [item["id"], item])
        ).values()
      ].map(x => {
        return { catalogId: 106, recordId: x.id || x.recordId };
      });
      console.log("serv", serv);
      setTimeout(() => {
        this.priceLoading = false;
      }, 1000);
      await this.socket.emit(
        "request",
        {
          resource: "booking",
          method: "patch",
          data: {
            id: this.bookingState.currentBookingId,
            servicesInputLink: serv
          }
        },
        res => console.log(res)
      );
      this.spinerForwardVisible = false;
      this.disabledFields = false;
    },
    deleteDates(x) {
      // if (x.target.value == 'dateAt') this.updateDateAt('2022-06-29');
      if (x.target.value == "dateAt") this.bookingState.dateAt = "";
      if (x.target.value == "dateTo") this.bookingState.dateTo = "";
    },
    // updPlaceToPick (place) {
    //   this.updatePlaceToPick(place)
    //   this.calculatePrice()
    // },
    // updPlaceToDrop (place) {
    //   this.updatePlaceToDrop(place)
    //   this.calculatePrice()
    // },
    // testVisible: async function(){
    //     console.log('method visibe')
    //     await this.reserveCar()
    //     return this.buttonVisible=!this.bookingState.error

    // },
    // mounted() {
    //   this.$store.watch(
    //     (state, getters) => {
    //       console.log(getters)
    //       state.dateAt},
    //     async (newValue, oldValue) => {
    //       if (newValue == true) {
    //         await this.reserveCar()
    //         console.log(oldValue)
    //         this.$store.buttonVisible = !this.$store.error
    //       }
    //     }
    //   )
    // },
    minDateTo(type) {
      if (type == "dateAt") return moment().format("YYYY-MM-DD");
      if (type == "dateTo") {
        if (this.bookingState.dateAt)
          return moment(this.bookingState.dateAt).format("YYYY-MM-DD");
        else return moment().format("YYYY-MM-DD");
      }
    },
    maxDateTo() {
      if (this.currentFilial.bookingPeriod)
        return moment()
          .add(this.currentFilial.bookingPeriod, "days")
          .format("YYYY-MM-DD");
      return undefined;
    },
    async handleForward() {
      switch (this.bookingState.step) {
        //   case null:
        case 1:
          this.spinerForwardVisible = true;
          if (
            this.bookingState.dateAt &&
            this.bookingState.dateTo &&
            this.bookingState.timeAt &&
            this.bookingState.timeTo
          ) {
            await this.startBooking();
            if (this.$metrika) this.$metrika.reachGoal("setTimes");
            if (this.bookingState.UTM.utm_refer) {
              this.promocode = this.bookingState.UTM.utm_refer[0].recordTitle;
              await this.checkPromoCode();
            }
            this.spinerForwardVisible = false;
            await this.getPriceData();
            // await this.calculatePrice();
          } else {
            this.updateErrorState("Укажите все даты");
            this.spinerForwardVisible = false;
          }
          if (
            this.bookingState.placeToPick &&
            this.bookingState.placeToPick.values[14].value
          ) {
            this.adresAt =
              this.bookingState.placeToPick.values[14].data.street_with_type +
              ", " +
              this.bookingState.placeToPick.values[14].data.house_type +
              " " +
              this.bookingState.placeToPick.values[14].data.house;
            this.updateAdresToPick(this.bookingState.placeToPick.values[14]);
          }
          if (
            this.bookingState.placeToDrop &&
            this.bookingState.placeToDrop.values[14].value
          ) {
            this.adresTo =
              this.bookingState.placeToDrop.values[14].data.street_with_type +
              ", " +
              this.bookingState.placeToDrop.values[14].data.house_type +
              " " +
              this.bookingState.placeToDrop.values[14].data.house;
            this.updateAdresToDrop(this.bookingState.placeToDrop.values[14]);
          }
          break;
        case 2:
          this.spinerForwardVisible = true;
          if (this.customPlaceToPick && this.bookingState.placeToPick.id == 2) {
            const newPlace = this.bookingState.placeToPick;
            newPlace.title = this.customPlaceToPick;
            this.updatePlaceToPick(newPlace);
          }
          if (this.customPlaceToDrop && this.bookingState.placeToDrop.id == 1) {
            const newPlace = this.bookingState.placeToDrop;
            newPlace.title = this.customPlaceToDrop;
            this.updatePlaceToDrop(newPlace);
          }
          await this.startBooking();
          if (this.$metrika) this.$metrika.reachGoal("setPlaces");
          this.spinerForwardVisible = false;
          break;
        case 3:
          this.spinerForwardVisible = true;
          if (
            this.user.files.passportFront.link &&
            this.user.files.passportBack.link &&
            this.user.files.driveLicenseFront.link &&
            this.user.files.driveLicenseBack.link
          ) {
            this.startBooking().then(() => {
              this.spinerForwardVisible = false;
              if (this.$metrika) this.$metrika.reachGoal("uploadDocuments");
            });
          } else {
            const saveSuccess = await this.saveFiles();
            if (saveSuccess) {
              this.startBooking().then(() => {
                if (this.$metrika) this.$metrika.reachGoal("uploadDocuments");
                this.spinerForwardVisible = false;
              });
            } else {
              this.updateErrorState("Попытка загрузить одинаковые документы");
              this.spinerForwardVisible = false;
            }
          }
          break;
        case 4:
          if (!this.bookingState.error) {
            window.open(this.bookingState.paymentLink, "_blank");
          } else console.log("error on last step");
          break;

        default:
          this.updateErrorState(
            "Технические работы на сайте, обратитесь в ватсапп."
          );
          console.log("error with switcher forward");
      }
    },
    async handleBack() {
      switch (this.bookingState.step) {
        case 1:
          console.log("cannot handleback");
          break;
        case 2:
          this.spinerForwardVisible = true;
          await this.backStepper(1);
          this.spinerForwardVisible = false;
          break;
        case 3:
          //this.calculatePrice()
          this.spinerForwardVisible = true;
          await this.backStepper(2);
          this.spinerForwardVisible = false;
          break;
        case 4:
          this.spinerForwardVisible = true;
          await this.backStepper(3);
          this.spinerForwardVisible = false;
          break;
        default:
          await this.backStepper(1);
          console.log("error with switcher back");
      }
    },
    async discard(id) {
      this.discardDialog = false;
      this.spinerDiscardVisible = true;
      await this.discardBooking(id);
      // this.updateBookingState();
      this.spinerDiscardVisible = false;
      // .then(() => this.buttonVisible = false)
    },
    async chooseDateAt() {
      this.$refs.dateAtMenu.save(this.bookingState.dateAt);
      if (!this.bookingState.dateTo)
        this.bookingState.dateTo = this.bookingState.dateAt;
      // this.calculatePrice();
      await this.getDetailsFilialsTime();
    },
    clickHours(v) {
      const string = v.toString();
      this.timepicker = string.length == 1 ? "0" + string : string;
    },
    allowedStep(m) {
      if (
        this.bookingState.reservedTimes &&
        this.bookingState.reservedTimes[this.timepicker]
      ) {
        return (
          !this.bookingState.reservedTimes[this.timepicker].includes(m) &&
          m % 15 === 0
        );
      } else return m % 15 === 0;
    },
    allowedHours(x) {
      if (this.bookingState.deliveryAtNight == "2") {
        return (
          moment(x, "HH").isBetween(
            moment(this.bookingState.schedule[0], "HH:mm"),
            moment(this.bookingState.schedule[1], "HH:mm"),
            undefined,
            "[]"
          ) && x.toString()
        );
      } else return x.toString();
    },

    checkedBox(service) {
      let flag = false;
      if (service && this.bookingService && this.bookingService.length) {
        for (let i = 0; i < this.bookingService.length; i++) {
          if (
            this.bookingService[i].id == service.id ||
            this.bookingService[i].recordId == service.id
          ) {
            flag = true;
            break;
          }
        }
      }
      return flag;
    },
    ...mapActions([
      "reserveCar",
      "getPriceData",
      "getBooking",
      // 'calculatePrice',
      "saveFiles",
      "updateUserFromBooking",
      "startBooking",
      "discardBooking",
      "getCarById",
      "getDetailsFilialsTime",
      "checkCode",
      "getUser",
      "backStepper",
      "findFilialById",
      "refreshInfoDocuments",
      "directlyPatch",
      "getDenyReasons"
      // 'refreshStoreBySocket',
    ]),
    ...mapMutations([
      "updateBookingState",
      "updatePlaceToPick",
      "updatePlaceToDrop",
      "updateChosenServices",
      "updatePriceText",
      "updateComment",
      "updateErrorState",
      "updateAdresToDrop",
      "updateAdresToPick",
      "resetBookingState"
      // 'updateDateAt',
      // 'updateDateTo',
    ])
  },
  computed: {
    currentTarif() {
      if (this.car?.currentTariffFull) {
        if (this.car?.currentTariffFull?.[15]?.[0] == 2) {
          return `${this.car.price}`;
        } else {
          return `${
            this.car.pricesList
              ? this.car.pricesList[0].fivePeriod
              : this.car.price
          }`;
        }
      } else {
        return this.car.price;
      }
    },
    bookingLocationOptionsPick() {
      const oj = {
        locations: [
          {
            region: this.currentCityAndRegionNames.regionName,
            city: this.currentCityAndRegionNames.name
          }
        ]
      };
      if (this.customCityNamePick)
        oj.locations[0].city = this.customCityNamePick;
      return oj;
    },
    bookingLocationOptionsDrop() {
      const oj = {
        locations: [
          {
            region: this.currentCityAndRegionNames.regionName,
            city: this.currentCityAndRegionNames.name
          }
        ]
      };
      if (this.customCityNameDrop)
        oj.locations[0].city = this.customCityNameDrop;
      return oj;
    },
    dateRangeText() {
      let text = "Выберите даты";
      if (this.dateAtText && this.dateToText)
        text = `c ${this.dateAtText} по ${this.dateToText}`;
      if (this.dateAtText && !this.dateToText)
        text = `c ${this.dateAtText} по ...`;
      if (!this.dateAtText && this.dateToText)
        text = `c ... по ${this.dateToText}`;
      return text;
    },
    calculateTarif() {
      if (!this.car.tariff || !this.bookingState.currentTarif) return null;
      const obj = {
        "1 день":
          Math.round(
            (this.car.price -
              (this.car.price * this.bookingState.currentTarif.firstPeriod) /
                100) /
              10
          ) * 10,
        "2-3 дня":
          Math.round(
            (this.car.price -
              (this.car.price * this.bookingState.currentTarif.secondPeriod) /
                100) /
              10
          ) * 10,
        "4-7 дней":
          Math.round(
            (this.car.price -
              (this.car.price * this.bookingState.currentTarif.thirdPeriod) /
                100) /
              10
          ) * 10,
        "8-14 дней":
          Math.round(
            (this.car.price -
              (this.car.price * this.bookingState.currentTarif.fourthPeriod) /
                100) /
              10
          ) * 10,
        "более 14 дней":
          Math.round(
            (this.car.price -
              (this.car.price * this.bookingState.currentTarif.fivePeriod) /
                100) /
              10
          ) * 10
      };
      return obj;
    },
    minimumTimeAt() {
      if (this.bookingState.dateAt) {
        const start = moment(this.bookingState.dateAt);
        const now = moment();
        if (now.isSame(start, "day")) return now.add(1, "hour").format("HH:mm");
      }
      return undefined;
    },
    deliveryPlacesPick() {
      let shownPlacesToPick = JSON.parse(
        JSON.stringify(this.currentFilial.deliveryPlaces)
      );
      if (
        this.pickTimeOutOfSchedule &&
        this.currentFilial.deliveryAvalible == 2
      ) {
        shownPlacesToPick = shownPlacesToPick.map((x, i) => {
          if (i == 0) return x;
          return (x["disabled"] = true && x);
        });
      }
      return shownPlacesToPick;
    },
    deliveryPlacesDrop() {
      let shownPlacesToDrop = JSON.parse(
        JSON.stringify(this.currentFilial.deliveryPlaces)
      );
      // Отключено т.к. сдача в нерабочее время на данный момент доступна всегда, даже если филиал не робит
      // if (
      //   this.dropTimeOutOfSchedule &&
      //   this.currentFilial.deliveryAvalible == 2
      // ) {
      //   shownPlacesToDrop = shownPlacesToDrop.map((x, i) => {
      //     if (i == 0) return x;
      //     return (x['disabled'] = true && x);
      //   });
      // }
      return shownPlacesToDrop;
    },
    fioComputedRules() {
      const rus = /^[ЁёА-я\s]*$/.test(this.user.title);
      const length =
        this.user.title.split(" ").filter(x => x != "").length >= 2;
      return rus && length;
    },
    bookingPeriod() {
      return `Авто свободен с ${moment().format("DD.MM.YYYY")} по ${moment()
        .add(this.currentFilial.bookingPeriod, "days")
        .format("DD.MM.YYYY")}.`;
    },
    docStepDisabled() {
      let hint = "";
      // hint += !this.user.title ? 'Заполните ФИО\n' : '';
      // hint += !this.fioComputedRules
      //   ? 'Заполните ФИО на русском языке полностью\n'
      //   : '';
      hint += !this.user.agreed ? "Обязательно согласиться с условиями\n" : "";
      // hint += !this.user.passport
      //   ? 'Заполните паспортные данные серия и номер\n'
      //   : '';
      // hint += !this.user.passportDate ? 'Заполните дату выдачи паспорта\n' : '';
      // hint += !this.user.birthday ? 'Заполните дату рождения\n' : '';
      hint += !(
        this.user.files.passportFront.link || this.user.files.passportFront.size
      )
        ? "Загрузите первую страницу паспорта\n"
        : "";
      hint += !(
        this.user.files.passportBack.link || this.user.files.passportBack.size
      )
        ? "Загрузите страницу с пропиской\n"
        : "";
      hint += !(
        this.user.files.driveLicenseFront.link ||
        this.user.files.driveLicenseFront.size
      )
        ? "Загрузите лицевую часть водительского удостоверения\n"
        : "";
      hint += !(
        this.user.files.driveLicenseBack.link ||
        this.user.files.driveLicenseBack.size
      )
        ? "Загрузите обратную часть водительского удостоверения\n"
        : "";
      hint += this.bookingState.error
        ? `Произошла ошибка ${this.bookingState.error}. Обязательно свяжитесь с нами для ее устранения.\n`
        : "";
      const arrayOfProblems = hint.split("\n").filter(x => x != "");
      return arrayOfProblems;
    },
    pickTimeOutOfSchedule() {
      if (!this.bookingState.timeAt) return null;
      const bool = moment(this.bookingState.timeAt, "HH:mm").isBetween(
        moment(this.currentFilial.schedule[0], "HH:mm"),
        moment(this.currentFilial.schedule[1], "HH:mm"),
        undefined,
        "[]"
      );
      return !bool;
    },
    dropTimeOutOfSchedule() {
      if (!this.bookingState.timeTo) return null;
      const bool = moment(this.bookingState.timeTo, "HH:mm").isBetween(
        moment(this.currentFilial.schedule[0], "HH:mm"),
        moment(this.currentFilial.schedule[1], "HH:mm"),
        undefined,
        "[]"
      );
      return !bool;
    },
    chipColor() {
      if (this.car.status) {
        const { id } = this.car.status;
        if (id == 0) return "green";
        if (id == 1) return "blue";
        if (id == 2) return "orange";
        if (id == 3) return "purple";
        if (id == 4) return "red darken-4";
      }
      return "grey";
    },

    dateAtFormated: {
      get() {
        return `${moment(this.bookingState.dateAt).format("DD.MM.YYYY")} в ${
          this.bookingState.timeAt
        }`;
      }
    },
    dateToFormated: {
      get() {
        return `${moment(this.bookingState.dateTo).format("DD.MM.YYYY")} в ${
          this.bookingState.timeTo
        }`;
      }
    },
    dateAtText() {
      // get() {
      if (this.bookingState.dateAt)
        return moment(this.bookingState.dateAt).format("DD.MM.YYYY");
      else return "Укажите дату";
      // },
      // set(d) {
      //   console.log('this sis d', d)
      //   return this.bookingState.commit('updateDateAt', d)
      //   // return this.updateDateAt('2022-06-29')
      // }
    },
    dateToText() {
      if (this.bookingState.dateTo)
        return moment(this.bookingState.dateTo).format("DD.MM.YYYY");
      else return "Укажите дату";
    },
    timeAtComputed: {
      get() {
        return this.bookingState.timeAt;
      },
      set(time) {
        return this.store.commit("UpdateTimeAt", time);
      }
    },
    timeToComputed: {
      get() {
        return this.bookingState.timeTo;
      },
      set(time) {
        return this.store.commit("UpdateTimeTo", time);
      }
    },
    dateAtComputed: {
      get() {
        return this.bookingState.dateAt;
      },
      set(date) {
        return this.store.commit("UpdateDateAt", date);
      }
    },
    dateToComputed: {
      get() {
        return this.bookingState.dateTo;
      },
      set(date) {
        return this.store.commit("UpdateDateTo", date);
      }
    },
    allFieldsDone: {
      get() {
        if (
          !!this.timeAtComputed &&
          !!this.timeToComputed &&
          !!this.dateAtComputed &&
          !!this.dateToComputed
        ) {
          const rnd = Math.random();
          return rnd;
        } else return false;
      }
    },
    priceTextComputed: {
      get() {
        if (this.bucket || this.promoLock) {
          // if (this.bookingState.placeToPick || this.bookingState.placeToDrop)
          return (
            this.bookingState.placeToPick?.id +
            this.bookingState.placeToDrop?.id +
            this.bookingState.discounts.title +
            this.bookingState.chosenServices?.length
          );
        } else return null;
      }
    },
    ...mapMutations([
      "updateDateAt",
      "updateDateTo",
      "updateTimeAt",
      "updateTimeTo",
      "updateButtonVisible",
      "updateUser",
      "updateStep",
      "handleInspections"
    ]),
    ...mapGetters([
      "bookingState",
      "allCars",
      "currentCityAndRegionNames",
      "car",
      "user",
      "bookingService",
      "currentFilial",
      "denyReasonsAuto",
      "inspections",
      "socket"
    ])
  },
  ...mapGetters([
    "bookingState",
    "car",
    "user",
    "currentFilial",
    "denyReasonsAuto",
    "inspections"
  ]),

  mutations: {
    updateTest(state, value) {
      state.test = value;
    }
  },
  watch: {
    allFieldsDone: {
      immediate: true,
      handler: async function() {
        this.messageError = "";
        //situtation when time to pick is before time to drop
        if (
          (this.bookingState.dateAt === this.bookingState.dateTo &&
            moment(this.bookingState.timeAt, "HH:mm").isSameOrAfter(
              moment(this.bookingState.timeTo, "HH:mm")
            )) ||
          moment(this.bookingState.dateAt).isAfter(
            moment(this.bookingState.dateTo)
          )
        ) {
          this.messageError =
            "Дата начала аренды должна быть раньше даты завершения :)";
          this.buttonVisible = false;
        } else {
          //if delete this if-block in form will apear red string which say "Переданы не все параметры" until user complete all fields
          if (
            this.bookingState.timeAt &&
            this.bookingState.timeTo &&
            this.bookingState.dateAt &&
            this.bookingState.dateTo
          ) {
            this.spinerForwardVisible = true;
            await this.reserveCar();
            this.spinerForwardVisible = false;
            this.buttonVisible = this.bookingState.error ? false : true;
          }
        }
      }
    }

    // priceTextComputed() {
    //   this.calculatePrice();
    // },
  },
  components: {
    ProfileCard,
    // DocLoad,
    Inspections,
    VueDadataTest,
    DateIntervalsAsset
  }
};
</script>

<style lang="scss">
.booking-scope {
  .menuIcon {
    position: absolute;
    // right: 10px;
    font-size: 2rem;
    left: 0.5rem;
    top: 0.5rem;
    z-index: 100000000;
    background-color: #424242;
    border-radius: 100%;
  }
  .stepperOneCard {
    // display: flex;
    align-content: center;
  }
  .datesAndTimes {
    justify-content: center;
    // max-width: 1000px;
  }
  .dates {
    max-width: 800px;
    min-width: 300px;
  }
  .times {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  .error-box {
    border-radius: 20px;
    opacity: 0.9;
  }
  .car_chip {
    position: absolute;
    top: 72%;
    margin-left: 2px;
  }

  .v-dialog--persistent {
    overflow: hidden;
  }
  .v-stepper__header {
    box-shadow: none;
  }
  .header-bron {
    line-height: 1.3em;
  }

  .date-at-fields {
    .v-input__control {
      .v-input__slot {
        .v-text-field__slot {
          label {
            pointer-events: none;
          }
          input {
            max-height: none !important;
            height: 100%;
          }
        }
      }
    }
  }
  .progresContainer {
    // width: 230px;
    // border: solid 1px #155b63;
    justify-content: center;
    // border-radius: 10px;
  }
  .headerText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .stepperHeader {
    height: 40px;
    align-content: center;
    font-size: 14px;
  }
  .price-text {
    transition-property: color;
    transition-duration: 0.5s;
    &--inactive {
      color: #4a4a4a;
    }
  }
  .loader {
    position: absolute;
    margin-left: 10em;
    margin-top: 2em;
  }

  // .price-text--inactive {

  //   transition-property: color;
  //   transition-duration: 0.5s;
  // }

  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
    }
  }
  @media only screen and (max-width: 560px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: none !important;
    }
  }
}
</style>
