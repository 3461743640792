<template>
  <v-card class="mb-6 px-3 mt-3">
    <v-overlay :value="spinerVisible || isFetching">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <div v-if="uploadPhoto">
      <div class="text-center" v-if="dialog">
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{ title }}
            </v-card-title>

            <v-card-text>
              {{ text }}
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <v-card-text class="text-center py-3">
        <h2>Прикрепите фото</h2>
      </v-card-text>
      <v-container fluid>
        <v-card-text class="text-center mb-3">
          <p>
            Загрузите обязательные фотографии и нажмите далее. Если машина имеет
            видимые повреждения загрузите их в соответствующую форму.
          </p>
        </v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="6" sm="6" md="6">
            <v-row align="center" justify="center">
              <v-card
                justify="center"
                outlined
                class="mb-3"
                align="center"
                width="180"
                height="200"
                elevation="5"
                @click="launchFilePicker('fileInspectFront')"
              >
                <v-container>
                  <v-btn
                    elevation="12"
                    icon
                    style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                    large
                    aria-label="upload inspect front"
                    ><v-icon v-if="isFileEmpty('inspectFront')"
                      >mdi-camera-plus</v-icon
                    >
                    <v-icon v-if="!isFileEmpty('inspectFront')"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                  </v-btn>

                  <v-img
                    v-if="inspectionsRequired.inspectFront.link"
                    :src="inspectionsRequired.inspectFront.link"
                    contain
                    height="150"
                    max-width="150"
                  ></v-img>
                  <v-img
                    v-else-if="inspectFront"
                    :src="inspectFront"
                    contain
                    height="150"
                    max-width="150"
                  ></v-img>
                  <v-img
                    v-if="isFileEmpty('inspectFront')"
                    src="https://storage.yandexcloud.net/a-prokat/1/8bcc6ffc-5b95-4694-a115-eb1637296bda/FRONT.jpg"
                    height="150"
                    max-width="150"
                    class="mb- default-img"
                    contain
                  ></v-img>
                  <input
                    type="file"
                    ref="fileInspectFront"
                    label="Машина спереди"
                    prepend-icon="mdi-camera"
                    truncate-length="25"
                    accept="image/*"
                    :rules="filesRules"
                    show-size
                    counter
                    outlined
                    style="display: none"
                    @change="onFileChange('inspectFront', $event.target.files)"
                  />

                  <v-card-text class="text--secondary py-1 px-0"
                    >Машина спереди</v-card-text
                  >
                </v-container>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-row align="center" justify="center">
              <v-card
                justify="center"
                outlined
                class="mb-3"
                align="center"
                width="180"
                height="200"
                elevation="0"
                @click="launchFilePicker('fileInspectRight')"
              >
                <v-container>
                  <v-btn
                    elevation="12"
                    icon
                    style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                    large
                    aria-label="upload inspect Right"
                    ><v-icon v-if="isFileEmpty('inspectRight')"
                      >mdi-camera-plus</v-icon
                    >
                    <v-icon v-if="!isFileEmpty('inspectRight')"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                  </v-btn>

                  <v-img
                    v-if="inspectionsRequired.inspectRight.link"
                    :src="inspectionsRequired.inspectRight.link"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-else-if="inspectRight"
                    :src="inspectRight"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-if="isFileEmpty('inspectRight')"
                    src="https://storage.yandexcloud.net/a-prokat/1/4a467c9c-5b38-4631-8342-0b53f3a3eb5f/RIGHT.jpg"
                    height="150"
                    max-width="150"
                    class="mb- default-img"
                    contain
                  ></v-img>
                  <input
                    type="file"
                    ref="fileInspectRight"
                    label="Машина справа"
                    prepend-icon="mdi-camera"
                    truncate-length="25"
                    accept="image/*"
                    :rules="filesRules"
                    show-size
                    counter
                    outlined
                    style="display: none"
                    @change="onFileChange('inspectRight', $event.target.files)"
                  />

                  <v-card-text class="text--secondary py-1 px-0"
                    >Машина справа</v-card-text
                  >
                </v-container>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-row align="center" justify="center">
              <v-card
                justify="center"
                outlined
                class="mb-3"
                align="center"
                width="180"
                height="200"
                elevation="0"
                @click="launchFilePicker('fileInspectLeft')"
              >
                <v-container>
                  <v-btn
                    elevation="12"
                    icon
                    style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                    large
                    aria-label="upload inspect Left"
                    ><v-icon v-if="isFileEmpty('inspectLeft')"
                      >mdi-camera-plus</v-icon
                    >
                    <v-icon v-if="!isFileEmpty('inspectLeft')"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                  </v-btn>

                  <v-img
                    v-if="inspectionsRequired.inspectLeft.link"
                    :src="inspectionsRequired.inspectLeft.link"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-else-if="inspectLeft"
                    :src="inspectLeft"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-if="isFileEmpty('inspectLeft')"
                    src="https://storage.yandexcloud.net/a-prokat/1/2307f0df-28ef-4862-9fc6-7e345ab4b230/LEFT.jpg"
                    height="150"
                    max-width="150"
                    class="mb- default-img"
                    contain
                  ></v-img>
                  <input
                    type="file"
                    ref="fileInspectLeft"
                    label="Машина слева"
                    prepend-icon="mdi-camera"
                    truncate-length="25"
                    accept="image/*"
                    :rules="filesRules"
                    show-size
                    counter
                    outlined
                    style="display: none"
                    @change="onFileChange('inspectLeft', $event.target.files)"
                  />

                  <v-card-text class="text--secondary py-1 px-0"
                    >Машина слева</v-card-text
                  >
                </v-container>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-row align="center" justify="center">
              <v-card
                justify="center"
                outlined
                class="mb-3"
                align="center"
                width="180"
                height="200"
                elevation="0"
                @click="launchFilePicker('fileInspectBack')"
              >
                <v-container>
                  <v-btn
                    elevation="12"
                    icon
                    style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                    large
                    aria-label="upload inspect Back"
                    ><v-icon v-if="isFileEmpty('inspectBack')"
                      >mdi-camera-plus</v-icon
                    >
                    <v-icon v-if="!isFileEmpty('inspectBack')"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                  </v-btn>

                  <v-img
                    v-if="inspectionsRequired.inspectBack.link"
                    :src="inspectionsRequired.inspectBack.link"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-else-if="inspectBack"
                    :src="inspectBack"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-if="isFileEmpty('inspectBack')"
                    src="https://storage.yandexcloud.net/a-prokat/1/8ce7a386-0c32-4917-9b17-a1793f8266ab/BACK4.jpg"
                    height="150"
                    max-width="150"
                    class="mb- default-img"
                    contain
                  ></v-img>
                  <input
                    type="file"
                    ref="fileInspectBack"
                    label="Машина сзади"
                    prepend-icon="mdi-camera"
                    truncate-length="25"
                    accept="image/*"
                    :rules="filesRules"
                    show-size
                    counter
                    outlined
                    style="display: none"
                    @change="onFileChange('inspectBack', $event.target.files)"
                  />

                  <v-card-text class="text--secondary py-1 px-0"
                    >Машина сзади</v-card-text
                  >
                </v-container>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="6" sm="6" md="6">
            <v-row align="center" justify="center">
              <v-card
                justify="center"
                outlined
                class="mb-3"
                align="center"
                width="180"
                height="200"
                elevation="0"
                @click="launchFilePicker('fileInspectTrunk')"
              >
                <v-container>
                  <v-btn
                    elevation="12"
                    icon
                    style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                    large
                    aria-label="upload inspect Trunk"
                    ><v-icon v-if="isFileEmpty('inspectTrunk')"
                      >mdi-camera-plus</v-icon
                    >
                    <v-icon v-if="!isFileEmpty('inspectTrunk')"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                  </v-btn>

                  <v-img
                    v-if="inspectionsRequired.inspectTrunk.link"
                    :src="inspectionsRequired.inspectTrunk.link"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-else-if="inspectTrunk"
                    :src="inspectTrunk"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-if="isFileEmpty('inspectTrunk')"
                    src="https://a-prokat.storage.yandexcloud.net/1/d3069332-3037-4c80-813e-083aa67d3849/%D0%B1%D0%B0%D0%B3%D0%B0%D0%B6%D0%BD%D0%B8%D0%BA2.jpg"
                    height="150"
                    max-width="150"
                    class="mb- default-img"
                    contain
                  ></v-img>
                  <input
                    type="file"
                    ref="fileInspectTrunk"
                    label="Багажник"
                    prepend-icon="mdi-camera"
                    truncate-length="25"
                    accept="image/*"
                    :rules="filesRules"
                    show-size
                    counter
                    outlined
                    style="display: none"
                    @change="onFileChange('inspectTrunk', $event.target.files)"
                  />

                  <v-card-text class="text--secondary py-1 px-0"
                    >Багажник</v-card-text
                  >
                </v-container>
              </v-card>
            </v-row>
          </v-col>
          <!-- <v-col cols="6" sm="6" md="6">
            <v-row align="center" justify="center">
              <v-card
                justify="center"
                outlined
                class="mb-3"
                align="center"
                width="180"
                height="200"
                elevation="0"
                @click="launchFilePicker('fileInspectSelfie')"
              >
                <v-container>
                  <v-btn
                    elevation="12"
                    icon
                    style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                    large
                    aria-label="upload inspect Selfie"
                    ><v-icon v-if="isFileEmpty('inspectSelfie')"
                      >mdi-camera-plus</v-icon
                    >
                    <v-icon v-if="!isFileEmpty('inspectSelfie')"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                  </v-btn>

                  <v-img
                    v-if="inspectionsRequired.inspectSelfie.link"
                    :src="inspectionsRequired.inspectSelfie.link"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-else-if="inspectSelfie"
                    :src="inspectSelfie"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-if="isFileEmpty('inspectSelfie')"
                    src="https://a-prokat.storage.yandexcloud.net/1/3e504014-9218-48f5-831b-6b37716752ca/%D1%81%D0%B5%D0%BB%D1%84%D0%B82.jpg"
                    height="150"
                    max-width="150"
                    class="mb- default-img"
                    contain
                  ></v-img>
                  <input
                    type="file"
                    ref="fileInspectSelfie"
                    label="Селфи"
                    prepend-icon="mdi-camera"
                    truncate-length="25"
                    accept="image/*"
                    :rules="filesRules"
                    show-size
                    counter
                    outlined
                    style="display: none"
                    @change="onFileChange('inspectSelfie', $event.target.files)"
                  />

                  <v-card-text class="text--secondary py-1 px-0"
                    >Селфи с паспортом</v-card-text
                  >
                </v-container>
              </v-card>
            </v-row>
          </v-col> -->
          <v-col cols="6" sm="6" md="6">
            <v-row align="center" justify="center">
              <v-card
                justify="center"
                outlined
                class="mb-3"
                align="center"
                width="180"
                height="200"
                elevation="0"
                @click="launchFilePicker('fileInspectFuelLevel')"
              >
                <v-container>
                  <v-btn
                    elevation="12"
                    icon
                    style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                    large
                    aria-label="upload inspect FuelLevel"
                    ><v-icon v-if="isFileEmpty('inspectFuelLevel')"
                      >mdi-camera-plus</v-icon
                    >
                    <v-icon v-if="!isFileEmpty('inspectFuelLevel')"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                  </v-btn>

                  <v-img
                    v-if="inspectionsRequired.inspectFuelLevel.link"
                    :src="inspectionsRequired.inspectFuelLevel.link"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-else-if="inspectFuelLevel"
                    :src="inspectFuelLevel"
                    height="150"
                    max-width="150"
                    contain
                  ></v-img>
                  <v-img
                    v-if="isFileEmpty('inspectFuelLevel')"
                    src="https://a-prokat.storage.yandexcloud.net/1/8fc35deb-6958-4935-914e-a88711a0655b/%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D1%8C.jpg"
                    height="150"
                    max-width="150"
                    class="mb- default-img"
                    contain
                  ></v-img>
                  <input
                    type="file"
                    ref="fileInspectFuelLevel"
                    label="Уровень топлива"
                    prepend-icon="mdi-camera"
                    truncate-length="25"
                    accept="image/*"
                    :rules="filesRules"
                    show-size
                    counter
                    outlined
                    style="display: none"
                    @change="
                      onFileChange('inspectFuelLevel', $event.target.files)
                    "
                  />

                  <v-card-text class="text--secondary py-1 px-0"
                    >Уровень топлива</v-card-text
                  >
                </v-container>
              </v-card>
            </v-row>
          </v-col>

          <v-col cols="6" sm="6" md="6" v-if="bookingState.status == 3">
            <v-row align="center" justify="center">
              <v-card
                justify="center"
                outlined
                class="mb-3"
                align="center"
                width="180"
                height="200"
                elevation="5"
                @click="launchFilePicker('fileAct')"
              >
                <v-container>
                  <v-btn
                    elevation="12"
                    icon
                    style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                    large
                    aria-label="upload act"
                    ><v-icon v-if="isFileEmpty('act')">mdi-camera-plus</v-icon>
                    <v-icon v-if="!isFileEmpty('act')"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                  </v-btn>

                  <v-img
                    v-if="inspectionsRequired.act.link"
                    :src="inspectionsRequired.act.link"
                    contain
                    height="150"
                    max-width="150"
                  ></v-img>
                  <v-img
                    v-else-if="act"
                    :src="act"
                    contain
                    height="150"
                    max-width="150"
                  ></v-img>
                  <v-img
                    v-if="isFileEmpty('act')"
                    src="https://a-prokat.storage.yandexcloud.net/1/0d9d7f96-a3b9-4819-b34f-4804ffc307e6/act.jpg"
                    height="150"
                    max-width="150"
                    class="mb- default-img"
                    contain
                  ></v-img>
                  <input
                    type="file"
                    ref="fileAct"
                    label="Машина спереди"
                    prepend-icon="mdi-camera"
                    truncate-length="25"
                    accept="image/*"
                    :rules="filesRules"
                    show-size
                    counter
                    outlined
                    style="display: none"
                    @change="onFileChange('act', $event.target.files)"
                  />

                  <v-card-text class="text--secondary py-1 px-0"
                    >Акт приёма</v-card-text
                  >
                </v-container>
              </v-card>
            </v-row>
          </v-col>
        </v-row>

        <v-expansion-panels>
          <v-expansion-panel :disabled="!allRequiredFieldsIsCapture">
            <v-expansion-panel-header
              >Загрузить фото повреждений</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row align="center" justify="center">
                <template v-for="(file, i) in [...inspectionsDamage, {}]">
                  <v-col cols="6" sm="6" md="6" :key="i" v-if="i <= 9">
                    <v-row align="center" justify="center">
                      <v-card
                        justify="center"
                        outlined
                        class="mb-3"
                        align="center"
                        width="180"
                        height="200"
                        elevation="0"
                        @click="launchFilePicker(`fileCarDamage_${i}`)"
                      >
                        <v-container>
                          <v-btn
                            elevation="12"
                            icon
                            style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                            large
                            aria-label="upload inspect front"
                            ><v-icon v-if="isFileEmpty(`carDamage_${i}`)"
                              >mdi-camera-plus</v-icon
                            >
                            <v-icon v-if="!isFileEmpty(`carDamage_${i}`)"
                              >mdi-checkbox-marked-circle</v-icon
                            >
                          </v-btn>

                          <v-img
                            v-if="
                              inspectionsDamage[i] && inspectionsDamage[i].link
                            "
                            :src="inspectionsDamage[i].link"
                            height="150"
                            max-width="150"
                            contain
                          ></v-img>
                          <v-img
                            v-else-if="!isFileEmpty(`carDamage_${i}`)"
                            :src="carDamage[i]"
                            height="150"
                            max-width="150"
                            contain
                          ></v-img>
                          <v-img
                            v-if="isFileEmpty(`carDamage_${i}`)"
                            src="https://storage.yandexcloud.net/a-prokat/1/62e6d166-fce1-4c23-8cad-86c384a79431/damage.jpg"
                            height="150"
                            max-width="150"
                            class="mb- default-img"
                            contain
                          ></v-img>
                          <input
                            type="file"
                            :ref="`fileCarDamage_${i}`"
                            label="Повреждение"
                            prepend-icon="mdi-camera"
                            truncate-length="25"
                            accept="image/*"
                            :rules="filesRules"
                            show-size
                            counter
                            outlined
                            style="display: none"
                            @change="onFileDamageChange(i, $event.target.files)"
                          />

                          <v-card-text class="text--secondary py-1 px-0">{{
                            i + 1 > inspectionsDamage.length
                              ? 'добавить новое'
                              : `повреждение #${i}`
                          }}</v-card-text>
                        </v-container>
                      </v-card>
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-alert
          transition="scale-transition"
          :value="!!errorWithFiles"
          type="error"
          class="mt-3"
          >{{ errorWithFiles }}</v-alert
        >
      </v-container>
    </div>
    <!-- Тут галерея для существующих фото -->
    <div v-else>
      <v-card class="oldPhotos" justify="center" align="center" elevation="0">
        <v-card-title>Результаты вашего осмотра</v-card-title>
        <v-carousel
          hide-delimiters
          height="auto"
          v-if="!isFetching"
          class="oldPhotos"
        >
          <v-carousel-item v-for="(link, i) in oldInspections" :key="i">
            <v-img :src="link" contain class="oldPhotos">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-2"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
        <v-dialog v-model="popupMorePhoto">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary"
            >добавить</v-btn
          >
        </template>

        <v-card>

          <v-row align="center" justify="center">
                <template v-for="(file, i) in [...morePhotos, {}]">
                  <v-col cols="6" sm="6" md="6" :key="i" v-if="i <= 9">
                    <v-row align="center" justify="center">
                      <v-card
                        justify="center"
                        outlined
                        class="mb-3"
                        align="center"
                        width="180"
                        height="200"
                        elevation="0"
                        @click="launchFilePicker(`fileMorePhotos_${i}`)"
                      >
                        <v-container>
                          <v-btn
                            elevation="12"
                            icon
                            style="
                      position: absolute;
                      z-index: 3;
                      left: 67.5px;
                      top: 70px;
                    "
                            large
                            aria-label="upload inspect front"
                            ><v-icon v-if="isFileEmpty(`morePhotos_${i}`)"
                              >mdi-camera-plus</v-icon
                            >
                            <v-icon v-if="!isFileEmpty(`morePhotos_${i}`)"
                              >mdi-checkbox-marked-circle</v-icon
                            >
                          </v-btn>

                          <v-img
                            v-if="
                              morePhotos[i] && morePhotos[i].link
                            "
                            :src="morePhotos[i].link"
                            height="120"
                            max-width="120"
                            contain
                          ></v-img>
                          <v-img
                            v-else-if="!isFileEmpty(`morePhotos_${i}`)"
                            :src="morePhotosAdds[i]"
                            height="120"
                            max-width="120"
                            contain
                          ></v-img>
                          <v-img
                            v-if="isFileEmpty(`morePhotos_${i}`)"
                            src="https://cdn1.iconfinder.com/data/icons/rounded-black-basic-ui/139/Photo_Add-RoundedBlack-512.png"
                            height="120"
                            max-width="120"
                            class="mb- default-img"
                            contain
                          ></v-img>
                          <input
                            type="file"
                            :ref="`fileMorePhotos_${i}`"
                            label="Повреждение"
                            prepend-icon="mdi-camera"
                            truncate-length="25"
                            accept="image/*"
                            :rules="filesRules"
                            show-size
                            counter
                            outlined
                            style="display: none"
                            @change="onFileMorePhotosChange(i, $event.target.files)"
                          />

                          <v-card-text class="text--secondary py-1 px-0">{{
                            i + 1 > morePhotos.length
                              ? 'добавить'
                              : `фото #${i}`
                          }}</v-card-text>
                        </v-container>
                      </v-card>
                    </v-row>
                  </v-col>
                </template>
              </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="addMorePhoto">
              Загрузить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-card>
    </div>
    <v-card-actions class="pa-5">
      <v-btn style="border: 1px solid; border-color: #1976D2;" @click="back()"
        >назад</v-btn
      >
      <v-spacer></v-spacer>
      <v-dialog v-model="popup" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            v-if="uploadPhoto"
            color="primary"
            :disabled="!allRequiredFieldsIsCapture || btnDownloadDisabled"
            >загрузить</v-btn
          >
        </template>

        <v-card>
          <v-card-text class="pt-4">
            {{
              inspectionsDamage.length
                ? 'Загрузить фото в систему?'
                : 'Вы уверены, что хотите продолжить без фотографий повреждений?'
            }}
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" text @click="popup = false">
              Нет
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="confirmForm">
              Да
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
// import moment from 'moment';
// import _ from "lodash";
export default {
  props: {
    uploadPhoto: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      popup: false,
      title: '',
      text: '',
      popupMorePhoto: false,
      morePhotosAdds: [],
      isFetching: false,
      spinerVisible: false,
      inspectFront: null,
      inspectRight: null,
      inspectLeft: null,
      inspectBack: null,
      inspectTrunk: null,
      // inspectSelfie: null,
      inspectFuelLevel: null,
      act: null,
      carDamage: [],
      name: null,
      errorWithFiles: null,
      activePicker: null,
      activePickerTwo: null,
      btnDownloadDisabled: false,
      titleRules: [
        (v) => !!v || 'Обязательное для заполнения',
        (v) => /^[ЁёА-я\s]*$/.test(v) || 'Введите Ваше ФИО на русском',
        (v) =>
          v.split(' ').filter((x) => x != '').length >= 2 ||
          'Введите полностью Фамилию Имя Отчество',
      ],
      agreeRules: [(v) => !!v || 'Обязательное условие'],
      filesRules: [
        (value) =>
          !value ||
          value.size < 50 * 1024 * 1024 ||
          'Файл слишком большой! Максимум 30мб',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'inspectionsRequired',
      'inspectionsDamage',
      'oldInspections',
      'morePhotos',
      'bookingState',
    ]),
    arrayOfDamage: function() {
      return this.inspectionsDamage + 1;
    },
    allRequiredFieldsIsCapture() {
      let countAllFiles = 0;
      let uploadedFiles = 0;
      for (let inspection in this.inspectionsRequired) {
        countAllFiles += 1;
        if (inspection === 'act' && this.bookingState.status != 3)
          countAllFiles -= 1;
        if (
          !(
            this.inspectionsRequired[inspection] &&
            Object.keys(this.inspectionsRequired[inspection]).length === 0 &&
            Object.getPrototypeOf(this.inspectionsRequired[inspection]) ===
              Object.prototype
          )
        ) {
          uploadedFiles += 1;
        }
      }
      return countAllFiles == uploadedFiles;
    },
    // birthdayDateAtText() {
    //   if (this.user.birthday)
    //     return moment(this.user.birthday).format('DD.MM.YYYY');
    //   else return '';
    // },
    // passportDateAtText() {
    //   if (this.user.passportDate)
    //     return moment(this.user.passportDate).format('DD.MM.YYYY');
    //   else return '';
    // },
  },
  //   watch: {
  //     passportDateMenu(val) {
  //       val && setTimeout(() => (this.activePicker = 'YEAR'));
  //     },
  //     birthdayDateMenu(val) {
  //       val && setTimeout(() => (this.activePickerTwo = 'YEAR'));
  //     },
  //     agree(val) {
  //       this.updateAgreement(val);
  //     },
  //   },
  arrayOfDamage() {
    return [...this.inspectionsDamage, {}];
  },
  watch: {
    arrayOfDamage: {
      immediate: true,
      handler: function() {
        return [...this.inspectionsDamage, {}];
      },
    },
  },
  methods: {
    back() {
      this.handleInspections();
    },
    async confirmForm() {
      this.popup = false;
      return await this.forward();
    },
    async addMorePhoto() {
      this.spinerVisible = true;
      const a = await this.postInspectionsFileKeysMore();
      console.log('this ius a', a)
      
      const b = await this.saveInspectionsMore();
      console.log('this ius b', b)
      this.popupMorePhoto = false
      await this.getOldPhotos();
      return
    },
    async forward() {
      this.spinerVisible = true;
      const filekeysSucces = await this.postInspectionsFileKeys();
      const saveSucces = await this.saveInspections();
      this.spinerVisible = false;
      this.dialog = true;
      if (filekeysSucces && saveSucces) {
        this.title = 'Осмотр сохранен';
        this.text = 'Машиной можно пользоваться';
        this.btnDownloadDisabled = true;
        setTimeout(() => {
          this.dialog = false;
          this.handleInspections();
          this.manipulateInspections({
            inspectionUploadButton: false,
            inspectionResultButton: true,
          });
        }, 3000);
      }
      if (!filekeysSucces || !saveSucces) {
        this.title = 'Упс';
        this.text = 'Что-то пошло не так, попробуйте еще раз.';
        setTimeout(() => {
          this.dialog = false;
        }, 3000);
      }
    },
    // savePassportDate(date) {
    //   this.$refs.passportDateMenu.save(date);
    // },
    // saveBirthdayDate(date) {
    //   this.$refs.birthdayDateMenu.save(date);
    // },
    onFileChange(name, file) {
      this.errorWithFiles = null;
      let imageFile = file[0];
      let flag = false;
      for (let userFile in this.inspectionsRequired) {
        if (
          this.inspectionsRequired[userFile] &&
          this.inspectionsRequired[userFile].size === imageFile.size
        ) {
          flag = true;
          break;
        }
      }
      if (flag) {
        this.errorWithFiles = 'Такое изображение уже загружено.';
        return;
      }
      if (file.length > 0) {
        if (!imageFile.type.match('image.*')) {
          // check whether the upload is an image
          this.errorWithFiles = 'Загрузите файл изображения.';
        } else if (imageFile.size > 10 * 1024 * 1024) {
          this.errorWithFiles = 'Максимальный разрешенный размер - 10мб';
        } else {
          this[name] = URL.createObjectURL(imageFile);
          this.changeInspection({ field: name, file: imageFile });
        }
      }
    },
    onFileDamageChange(index, file) {
      this.$forceUpdate();
      this.errorWithFiles = null;
      let imageFile = file[0];
      let flag = false;
      for (let userFile in this.inspectionsDamage) {
        if (
          this.inspectionsDamage[userFile] &&
          this.inspectionsDamage[userFile].size === imageFile.size
        ) {
          flag = true;
        }
      }
      if (flag) {
        this.errorWithFiles = 'Такое изображение уже загружено.';
        return;
      }
      if (file.length > 0) {
        if (!imageFile.type.match('image.*')) {
          // check whether the upload is an image
          this.errorWithFiles = 'Загрузите файл изображения.';
        } else if (imageFile.size > 10 * 1024 * 1024) {
          this.errorWithFiles = 'Максимальный разрешенный размер - 10мб';
        } else {
          this.carDamage[index] = URL.createObjectURL(imageFile);
          this.changeDamageInspection({ index: index, file: imageFile });
          this.carDamage = [...this.carDamage, null];
        }
      }
    },
    onFileMorePhotosChange(index, file) {
      this.$forceUpdate();
      this.errorWithFiles = null;
      let imageFile = file[0];
      let flag = false;
      for (let userFile in this.morePhotos) {
        if (
          this.morePhotos[userFile] &&
          this.morePhotos[userFile].size === imageFile.size
        ) {
          flag = true;
        }
      }
      if (flag) {
        this.errorWithFiles = 'Такое изображение уже загружено.';
        return;
      }
      if (file.length > 0) {
        if (!imageFile.type.match('image.*')) {
          // check whether the upload is an image
          this.errorWithFiles = 'Загрузите файл изображения.';
        } else if (imageFile.size > 10 * 1024 * 1024) {
          this.errorWithFiles = 'Максимальный разрешенный размер - 10мб';
        } else {
          this.morePhotosAdds[index] = URL.createObjectURL(imageFile);
          this.changeMorePhotosInspection({ index: index, file: imageFile });
          this.morePhotosAdds = [...this.morePhotosAdds, null];
        }
      }
    },
    isFileEmpty(name) {
      if (name.includes('carDamage')) {
        const index = name.split('_')[1];
        if (
          this.inspectionsDamage[index] &&
          (this.inspectionsDamage[index].size ||
            this.inspectionsDamage[index].link)
        ) {
          return false;
        } else {
          return true;
        }
      }
      if (name.includes('morePhotos')) {
        const index = name.split('_')[1];
        if (
          this.morePhotos[index] &&
          (this.morePhotos[index].size ||
            this.morePhotos[index].link)
        ) {
          return false;
        } else {
          return true;
        }
      }
      if (
        this.inspectionsRequired[name] &&
        (this.inspectionsRequired[name].size ||
          this.inspectionsRequired[name].link)
      ) {
        return false;
      }
      return true;
    },
    launchFilePicker(name) {
      if (name.includes('CarDamage') || name.includes('MorePhotos')) {
        this.$refs[name][0].click();
        return;
      }
      this.$refs[name].click();
    },
    ...mapActions([
      'getRegions',
      'postInspectionsFileKeys',
      'postInspectionsFileKeysMore',
      'saveInspections',
      'saveInspectionsMore',
      'getOldPhotos',
    ]),
    ...mapMutations([
      //   'driveLicenseBackhanged',
      //   'driveLicenseFrontChanged',
      //   'passportBackhanged',
      //   'inspectFrontChanged',
      //   'updateErrorState',
      //   'updateAgreement',
      'handleInspections',
      'changeInspection',
      'changeDamageInspection',
      'manipulateInspections',
      'changeMorePhotosInspection'
    ]),
  },
  // watch: {
  //     onFileChange() {
  //         this.isFileEmpty()
  //     }
  // },
  //   mutations: {
  //     ...mapMutations(['updateUser']),
  //   },
  // mounted() {
  // this.getRegions()
  // }
  async created() {
    if (!this.uploadPhoto) {
      this.isFetching = true;
      await this.getOldPhotos();
      this.isFetching = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.default-img {
  filter: grayscale;
}
.oldPhotos {
  height: 700px;
}
@media only screen and (max-width: 600px) {
  .oldPhotos {
    height: 500px;
  }
}
</style>
