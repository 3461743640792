<template>
  <v-layout justify-center align-content-center>
    <v-card elevation="0" max-width="300" class="px-2">
      <v-card-text class="text-center">
        {{ firstText }}
        <span v-if="firstText.includes('повторно')">
          {{ timer != '00:00' ? timer : '' }}
        </span>
      </v-card-text>
      <!-- <div @click="goToWhatsapp" v-if="iconVisible">
        <p class="text-center mb-0 green--text">Перейти в Whatsapp</p>
        <v-card-actions class="justify-center mt-n1">
          <v-btn elevation="2" fab large>
            <v-icon x-large color="green accent-3" class="pulse"
              >mdi-whatsapp</v-icon
            >
          </v-btn>
        </v-card-actions>
      </div> -->
      <v-card-text class="text-center" v-if="secondText.includes('Повторный') && btnDisabled">
        {{ secondText }} {{ timer != '00:00' ? timer : '' }}
      </v-card-text>
      <v-card-actions class="justify-center">
        <input
          v-if="!btnDisabled"
          type="text"
          v-mask="mask"
          v-model="phone"
          class="white--text phone-auth mb-3"
          :disabled="btnDisabled"
        />
      </v-card-actions>
      <div v-if="iconVisible">
      <AuthCode :hide="goBack" :showCodeField="showCodeField"/>
      </div>
      <v-card-actions>
        <v-btn @click="goBack" :disabled="btnDisabled">отмена</v-btn>

        <v-spacer></v-spacer>
        <v-btn
          :disabled="!(phone.length === 17) || btnDisabled"
          @click="start()"
          outlined
          color="blue"
          :loading="spinner"
          >{{
            timer == '' || timer == '00:00' ? btnText : 'Повторно ' + timer
          }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-layout>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import AuthCode from './utils/AuthCode.vue';
export default {
  components: { AuthCode },
  props: {
    sendPhone: {
      type: Function,
      default: () => {
        console.log('default send Phone');
        return false;
      },
    },
    showCodeField: {
      type: Boolean,
      default: true,
    },
    // goBackFunction: {
    //   type: Function,
    //   default: () => {
    //     console.log('default send Phone');
    //     return false;
    //   },
    // },
    text: {
      type: String,
      default:
        'Для продолжения бронирования введите номер телефона, к которому привязан Whatsapp:',
    },
    btnText: {
      type: String,
      default: 'Забронировать',
    },
  },
  data: () => ({
    mask: [
      '+7(',
      /\d/,
      /\d/,
      /\d/,
      ')-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ],
    // text:
    //   'Для продолжения бронирования введите номер телефона, к которому привязан Whatsapp:',
    spinner: false,
    // btnText: 'Забронировать',
    btnDisabled: false,
    btnDiscardVisible: false,
    inputDisabled: false,
    minutes: '',
    seconds: '',
    countDown: 5 * 60,
    timer: '',
    phone: '+7',
    iconVisible: false,
  }),
  computed: {
    ...mapGetters(['user', 'car', 'bookingState']),
    firstText() {
      let text = this.text;
      if (this.text.includes('Повторный')) {
        text = this.text.split('. Повторный')[0];
      }
      return text;
    },
    secondText() {
      let text = this.text;
      if (this.text.includes('Повторный')) {
        text = 'Повторный' + this.text.split('. Повторный')[1];
      }
      if (this.text.includes('повторно')) {
        text = 'через' + this.text.split('через')[1];
      }
      return text;
    },
  },
  methods: {
    goBack() {
      // this.goBackFunction()
      this.updateCar();
      this.updateBookingState();
      this.$emit('goBackFunction');
      // this.updateBookingState();
    },
    goToWhatsapp() {
      window.open('https://wa.me/79009210281').focus();
    },
    async start() {
      try {
        if (this.$metrika) this.$metrika.reachGoal('otpravil telefon');
        this.spinner = true;
        this.updatePhone(this.phone);
        this.updateStep(0);
        const start = await this.sendPhone();
        if (start) {
          this.iconVisible = true;
          if (this.user.id) this.logoutUser(); // если он уже авторизован, но пришел не через ватсап
          this.minutes = start?.timeMinutes;
          this.seconds = start?.timeSeconds;
          this.spinner = false;
          this.countDown = 5 * 60;
          this.btnDiscardVisible = true;
          // this.changeBtnText(start.btnText);
          this.btnDisabled = true;
          this.changeText(start.message.split('через')[0] + 'через');
          this.setCountDown();
          setTimeout(() => {
            this.doCountDown();
            setTimeout(() => {
              // this.updateBookingState();
              this.iconVisible = false;
              this.btnDisabled = false;
              this.changeBtnText('Отправить код');
              this.changeText(this.text);
              this.changeTimer('');
              this.btnDiscardVisible = false;
            }, 1000 * this.countDown + 1000);
          }, 0);
        } else this.changeText('Проверьте корректность введенного номера.');
      } catch (e) {
        console.log(e);
      }
    },

    setCountDown() {
      if (this.seconds || this.minutes) {
        this.countDown = parseInt(this.minutes) * 60 + parseInt(this.seconds);
      }
    },

    doCountDown() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          const timer = moment()
            .minutes(0)
            .seconds(this.countDown)
            .format('mm:ss');
          this.changeTimer(timer);
          this.doCountDown();
        }, 1000);
      }
    },
    changeTimer(string) {
      this.timer = string;
    },
    changeText(text) {
      this.text = text;
    },
    changeBtnText(text) {
      this.btnText = text;
    },
    ...mapActions(['logoutUser']),
    ...mapMutations(['updateBookingState', 'updatePhone', 'updateStep','updateCar']),
  },
};
</script>

<style lang="scss" scoped>
.phone-auth {
  // background-color: gray;
  outline: transparent;
  transition: border-color;
  border-style: solid;
  border-color: whitesmoke;
  text-align: center;
  border-radius: 5px;
}
.pulse {
  // margin:100px;
  // display: block;
  // cursor: pointer;
  border-radius: 50%;
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(32, 194, 32, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 50px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 204, 52, 0.4);
    box-shadow: 0 0 0 0 rgba(71, 204, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 50px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 50px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
</style>
